import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-exit-button',
  templateUrl: './exit-button.component.html',
  styleUrls: ['./exit-button.component.css']
})
export class ExitButtonComponent implements OnInit {
  returnUrl: any;
  constructor(
    private activeRoute: ActivatedRoute, private router: Router
  ) {}

  ngOnInit(): void {
    this.returnUrl = '/';
  }

  exit() {    
    this.router.navigateByUrl(this.returnUrl);
  }
}
