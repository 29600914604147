<section class="header headLogged">
  <div class="large-wrapper">
    <div class="headFlex">
      <a href="#" class="logo"
        ><img
          src="../../../../../assets/oprovi_beta2.svg"
          height=""
          width=""
          alt="logo"
      /></a>
      <div class="right">
        <app-advisor-status
          *ngIf="userDetails.type == 'ADVISOR'"
        ></app-advisor-status>

        <a
          *ngIf="!isUploadWorkPage && userDetails.type !== 'ADVISOR'"
          href="#"
          routerLink="/upload-work"
          class="button heightBtn greenBtn"
          >Get Feedback</a
        >
        <app-notifications></app-notifications>
        <div class="dropdown profileDropdown webProfileDropdown">
          <button
            class="dropdown-toggle button transBtn"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <!-- <img
              [src]="userDetails.image"
              onError="this.src='../../../../../assets/profile-avatar.jpeg'"
              height=""
              width=""
              alt="logo"
            /> -->
            <app-my-profile-picture
              [image]="userDetails.image"
            ></app-my-profile-picture>
            Hello, {{ userDetails.full_name }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <div class="profileUserInfo">
              <!-- <div class="couponSec">
                <p>Have a coupon code? <a href="#">Apply</a></p>
              </div> -->
              <p>
                You are:
                <span *ngIf="actualUserType == 'ADVISOR'; else currentUserType"
                  >User and Advisor</span
                ><ng-template #currentUserType><span>User</span></ng-template>
              </p>
              <h6
                *ngIf="
                  actualUserType == 'ADVISOR' && userDetails.type == 'ADVISOR'
                "
              >
                Currently an Advisor
              </h6>
              <h6
                *ngIf="
                  actualUserType == 'ADVISOR' && userDetails.type == 'NORMAL'
                "
              >
                Currently a User
              </h6>
              <p *ngIf="actualUserType == 'ADVISOR'">
                Advisor name: <span>{{ userDetails.full_name }}</span>
              </p>
              <p *ngIf="actualUserType == 'NORMAL'">
                User name: <span>{{ userDetails.full_name }}</span>
              </p>
              <p *ngIf="actualUserType == 'ADVISOR'">
                Advisor type:
                <span class="expLabel"
                  ><img
                    *ngIf="userType?.advisor_level != 'NEW'"
                    src="../../../../assets/{{
                      userType?.advisor_level == 'PRIME'
                        ? 'prime.svg'
                        : 'experienced.svg'
                    }}"
                    height=""
                    width=""
                    alt="dp"
                    class="badgeIcon"
                  />{{ userDetails?.advisor_level }}</span
                >
              </p>
            </div>
            <!-- <div class="langToggleWrap">
              <div class="box">
                <label class="form-check">
                  <input
                    checked="true"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                  />
                  <span for="inlineRadio1"
                    ><img
                      class="icon"
                      src="../../../../../assets/uk.svg"
                      height=""
                      width=""
                      alt="name"
                    />English
                  </span>
                </label>
              </div>
              <div class="box">
                <label class="form-check">
                  <input
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option2"
                  />
                  <span for="inlineRadio2"
                    ><img
                      class="icon"
                      src="../../../../../assets/uk.svg"
                      height=""
                      width=""
                      alt="name"
                    />French</span
                  >
                </label>
              </div>
            </div> -->
            <a
              class="dropdown-item"
              *ngIf="
                actualUserType == 'ADVISOR' && userDetails.type == 'ADVISOR'
              "
              routerLink="/profile/{{ userDetails.id }}"
              href="#"
              ><img
                src="../../../../../assets/Icon-profile.svg"
                height=""
                width=""
                alt="name"
              /><img
                src="../../../../../assets/Icon-profile.svg"
                height=""
                width=""
                alt="name"
                style="
                  filter: brightness(0) saturate(100%) invert(100%) sepia(20%)
                    saturate(1%) hue-rotate(34deg) brightness(109%)
                    contrast(101%);
                "
              />View Profile</a
            >
            <a
              class="dropdown-item"
              routerLink="/dashboard/account-settings"
              href="#"
              ><img
                src="../../../../../assets/account-setting-green.svg"
                height=""
                width=""
                alt="name"
              /><img
                src="../../../../../assets/account-setting-white.svg"
                height=""
                width=""
                alt="name"
              />Account Settings</a
            >
            <a
              class="dropdown-item"
              routerLink="/dashboard/help-center"
              href="#"
              ><img
                src="../../../../../assets/help-center-green.svg"
                height=""
                width=""
                alt="name"
              /><img
                src="../../../../../assets/help-center-white.svg"
                height=""
                width=""
                alt="name"
              />Help Center</a
            >
            <a class="dropdown-item" (click)="logout()"
              ><img
                src="../../../../../assets/logout-green.svg"
                height=""
                width=""
                alt="name"
              /><img
                src="../../../../../assets/logout-white.svg"
                height=""
                width=""
                alt="name"
              />Log Out</a
            >
          </div>
        </div>
        <p
          class="headCoinsCount"
          *ngIf="userType == 'ADVISOR' && userDetails.type == 'ADVISOR'"
        >
          <!-- ${{ userDetails.balance || 0 }} -->
          ${{ totalBalance ? (totalBalance | number : "1.2-2") : 0 }}
          <!--<sup>coins</sup>-->
        </p>

        <p class="headCoinsCount" *ngIf="userType == 'NORMAL'">
          ${{ 0 }}
          <!--<sup>coins</sup>-->
        </p>

        <div class="mobileMenu">
          <p-button type="button" (click)="visibleSidebar1 = true"
            ><img
              src="../../../../../assets/mobile-menu.svg"
              height=""
              width=""
              alt="name"
          /></p-button>
        </div>
      </div>
    </div>
  </div>
  <div
    [ngClass]="{ userHeadBottom: userDetails.type !== 'ADVISOR' }"
    class="headBottom"
  >
    <div class="large-wrapper test">
      <div class="headFlex">
        <ul class="mainMenu">
          <li>
            <a routerLink="/home" routerLinkActive="active" href="javascript:;"
              >Home</a
            >
          </li>
          <li *ngIf="userDetails.type == 'ADVISOR'">
            <a
              (click)="navigateToMyWorks('new')"
              routerLinkActive="active"
              routerLink="/dashboard/my-works/list/new"
              href="javascript:;"
              >My Works<span
                *ngIf="myWorksNotificationCount > 0"
                class="notifyCount"
              ></span
            ></a>
          </li>
          <li *ngIf="userDetails.type !== 'ADVISOR'">
            <a
              (click)="navigateToMyWorks('on-going')"
              routerLink="/dashboard/my-works/list/on-going"
              routerLinkActive="active"
              href="#"
              >My Content
              <span
                *ngIf="myWorksNotificationCount > 0"
                class="notifyCount"
              ></span
            ></a>
          </li>
          <li *ngIf="userDetails.type !== 'ADVISOR'">
            <a
              routerLink="/discover-advisors"
              routerLinkActive="active"
              href="#"
              >Discover Advisors</a
            >
          </li>
          <li *ngIf="userDetails.type !== 'ADVISOR'">
            <a routerLink="/examples" routerLinkActive="active" href="#"
              >Examples</a
            >
          </li>
          <li>
            <a
              routerLink="/dashboard/account-settings/money-management"
              routerLinkActive="active"
              >Money Management</a
            >
          </li>
          <li *ngIf="userDetails.type == 'ADVISOR'">
            <a
              routerLink="/increase-exposure"
              routerLinkActive="active"
              href="#"
              >Increase exposure</a
            >
          </li>
          <li *ngIf="actualUserType !== 'ADVISOR'">
            <a
              routerLink="/become-an-advisor"
              routerLinkActive="active"
              href="#"
              >Become an Advisor</a
            >
          </li>
        </ul>

        <a (click)="switchAccount()" class="button transBtn"
          ><img
            src="../../../../../assets/switch-icon.svg"
            height=""
            width=""
            alt="logo"
          />

          <span *ngIf="userDetails.type !== 'ADVISOR'">Log in as Advisor</span>
          <span *ngIf="userDetails.type == 'ADVISOR'">Log in as User</span>
        </a>
      </div>
    </div>
  </div>

  <p-sidebar
    class="mobileMenuStyle"
    [style.position]="'relative'"
    [(visible)]="visibleSidebar1"
    [baseZIndex]="10000"
  >
    <img
      src="/assets/oprovi_beta2.svg"
      style="
        display: inline-block;
        position: absolute;
        top: -35px;
        left: 20px;
        width: 59px;
        z-index: 999;
      "
      alt=""
    />
    <div class="dropdown profileDropdown sideBarHead">
      <div class="sbhUserSec">
        <p
          class="headCoinsCount"
          *ngIf="actualUserType == 'ADVISOR' && userDetails.type == 'ADVISOR'"
        >
          {{ userDetails.balance || 0 }} <sup><b>$</b></sup>
        </p>
        <p class="headCoinsCount" *ngIf="userType == 'NORMAL'">
          ${{ 0 }}
          <!--<sup>coins</sup>-->
        </p>
        <button
          class="button transBtn"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <!-- <img
            [src]="userDetails.image"
            onError="this.src='../../../../../assets/profile-avatar.jpeg'"
            height=""
            width=""
            alt="logo"
          /> -->
          <app-my-profile-picture
            [image]="userDetails.image"
          ></app-my-profile-picture>
          Hello, {{ userDetails.full_name }}
        </button>
      </div>
      <div class="notifMobSec">
        <app-notifications></app-notifications>
        <app-advisor-status
          *ngIf="userDetails.type == 'ADVISOR'"
        ></app-advisor-status>
      </div>
    </div>

    <div class="sbAfterNotfSec">
      <ul class="mainMenu">
        <li>
          <a routerLink="/home" routerLinkActive="active" href="javascript:;"
            >Home</a
          >
        </li>
        <li *ngIf="userDetails.type == 'ADVISOR'">
          <a
            data-toggle="collapse"
            data-target="#subMenu"
            aria-expanded="false"
            aria-controls="collapseExample"
            href="javascript:;"
            >My Works
            <img
              src="../../../../../assets/green-vector-down.svg"
              height=""
              width=""
              alt="logo" />
            <span
              *ngIf="myWorksNotificationCount > 0"
              class="notifyCount"
            ></span
          ></a>
          <div class="subMenu collapse" id="subMenu">
            <ul>
              <li>
                <a
                  (click)="navigateToMyWorks('new')"
                  routerLink="/dashboard/my-works/list/new"
                  routerLinkActive="active"
                  href="javascript:;"
                  href="#"
                  >My Work</a
                >
              </li>
              <li>
                <a
                  routerLink="/dashboard/messages"
                  routerLinkActive="active"
                  href="javascript:;"
                  href="#"
                  >Messages</a
                >
              </li>
              <li>
                <a
                  routerLink="/dashboard/account-settings"
                  routerLinkActive="active"
                  href="javascript:;"
                  href="#"
                  >Account Settings</a
                >
              </li>
              <li *ngIf="userDetails.type == 'ADVISOR'">
                <a
                  routerLink="/dashboard/my-portfolio"
                  routerLinkActive="active"
                  href="javascript:;"
                  href="#"
                  >My Portfolio</a
                >
              </li>
            </ul>
          </div>
        </li>
        <li *ngIf="userDetails.type !== 'ADVISOR'">
          <a
            (click)="navigateToMyWorks('on-going')"
            routerLink="/dashboard/my-works/list/on-going"
            routerLinkActive="active"
            href="#"
            >My Content<span
              *ngIf="myWorksNotificationCount > 0"
              class="notifyCount"
            ></span
          ></a>
        </li>
        <li *ngIf="userDetails.type !== 'ADVISOR'">
          <a routerLink="/discover-advisors" routerLinkActive="active" href="#"
            >Discover Advisors</a
          >
        </li>
        <li *ngIf="userDetails.type !== 'ADVISOR'">
          <a routerLink="/examples" href="#">Examples</a>
        </li>
        <li>
          <a
            routerLink="/dashboard/account-settings/money-management"
            routerLinkActive="active"
            >Money Management</a
          >
        </li>
        <li *ngIf="userDetails.type == 'ADVISOR'">
          <a routerLink="/increase-exposure" routerLinkActive="active" href="#"
            >Increase exposure</a
          >
        </li>

        <li *ngIf="actualUserType !== 'ADVISOR'">
          <a routerLink="/become-an-advisor" routerLinkActive="active" href="#"
            >Become an Advisor</a
          >
        </li>
      </ul>

      <a
        *ngIf="!isUploadWorkPage && userDetails.type !== 'ADVISOR'"
        href="#"
        routerLink="/upload-work"
        class="button heightBtn greenBtn"
        >Get Feedback</a
      >

      <a
        *ngIf="actualUserType == 'ADVISOR'"
        (click)="switchAccount()"
        class="button transBtn logSwitchBtn"
        ><img
          src="../../../../../assets/switch-icon.svg"
          height=""
          width=""
          alt="logo"
        />

        <span *ngIf="userDetails.type !== 'ADVISOR'">Log in as Advisor</span>
        <span *ngIf="userDetails.type == 'ADVISOR'">Log in as User</span>
      </a>

      <div class="dropdown profileDropdown">
        <div class="dropdown-menu show" aria-labelledby="dropdownMenuButton">
          <!-- <div class="profileUserInfo">
            <p>You are: <span>User and Advisor</span> </p>
            <h6>Currently an Advisor3</h6>
            <p>Advisor name: <span>David Flors</span></p>
            <p>Advisor type:  <span class="expLabel"><img
              src="../../../../assets/prime.svg"
              height=""
              width=""
              alt="dp"
              class="badgeIcon"
            />Experienced</span></p>
          </div> -->
          <div class="profileUserInfo">
            <p>
              You are:
              <span *ngIf="actualUserType == 'ADVISOR'; else currentUserType"
                >User and Advisor</span
              ><ng-template #currentUserType><span>User</span></ng-template>
            </p>
            <h6
              *ngIf="
                actualUserType == 'ADVISOR' && userDetails.type == 'ADVISOR'
              "
            >
              Currently an Advisor
            </h6>
            <h6
              *ngIf="
                actualUserType == 'ADVISOR' && userDetails.type == 'NORMAL'
              "
            >
              Currently a User
            </h6>
            <p *ngIf="actualUserType == 'ADVISOR'">
              Advisor name: <span>{{ userDetails.full_name }}</span>
            </p>
            <p *ngIf="actualUserType == 'NORMAL'">
              User name: <span>{{ userDetails.full_name }}</span>
            </p>
            <p *ngIf="actualUserType == 'ADVISOR'">
              Advisor type:
              <span class="expLabel"
                ><img
                  src="../../../../assets/prime.svg"
                  height=""
                  width=""
                  alt="dp"
                  class="badgeIcon"
                />{{ userDetail?.advisor_level }}</span
              >
            </p>
          </div>
          <!-- <div class="langToggleWrap">
            <div class="box">
              <label class="form-check">
                <input
                  checked="true"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="option1"
                />
                <span for="inlineRadio1"
                  ><img
                    class="icon"
                    src="../../../../../assets/uk.svg"
                    height=""
                    width=""
                    alt="name"
                  />English
                </span>
              </label>
            </div>
            <div class="box">
              <label class="form-check">
                <input
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="option2"
                />
                <span for="inlineRadio2"
                  ><img
                    class="icon"
                    src="../../../../../assets/uk.svg"
                    height=""
                    width=""
                    alt="name"
                  />French</span
                >
              </label>
            </div>
          </div> -->
          <a
            class="dropdown-item"
            *ngIf="actualUserType == 'ADVISOR' && userDetails.type == 'ADVISOR'"
            routerLink="/profile/{{ userDetails.id }}"
            href="#"
            ><img
              src="../../../../../assets/Icon-profile.svg"
              height=""
              width=""
              alt="name"
            /><img
              src="../../../../../assets/Icon-profile.svg"
              height=""
              width=""
              alt="name"
              style="
                filter: brightness(0) saturate(100%) invert(100%) sepia(20%)
                  saturate(1%) hue-rotate(34deg) brightness(109%) contrast(101%);
              "
            />View Profile</a
          >
          <a
            class="dropdown-item"
            routerLink="/dashboard/account-settings"
            href="#"
            ><img
              src="../../../../../assets/account-setting-green.svg"
              height=""
              width=""
              alt="name"
            /><img
              src="../../../../../assets/account-setting-white.svg"
              height=""
              width=""
              alt="name"
            />Account Settings</a
          >
          <a class="dropdown-item" routerLink="/dashboard/help-center" href="#"
            ><img
              src="../../../../../assets/help-center-green.svg"
              height=""
              width=""
              alt="name"
            /><img
              src="../../../../../assets/help-center-white.svg"
              height=""
              width=""
              alt="name"
            />Help Center</a
          >
          <a class="dropdown-item" (click)="logout()"
            ><img
              src="../../../../../assets/logout-green.svg"
              height=""
              width=""
              alt="name"
            /><img
              src="../../../../../assets/logout-white.svg"
              height=""
              width=""
              alt="name"
            />Log Out</a
          >
        </div>
      </div>
    </div>
  </p-sidebar>
</section>
