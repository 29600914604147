import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SocialAuthService,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';
import { URLConfig } from 'src/app/configs/url-config';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TermsAndConditionsModalComponent } from '../Modal/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { SendEmailReferredByModalComponent } from '../Modal/send-email-referred-by-modal/send-email-referred-by-modal.component';
import { DOCUMENT, Location } from '@angular/common';
import { BetaVersionModalComponent } from '../Modal/beta-version-modal/beta-version-modal.component';

@Component({
  selector: 'app-social-signup-buttons',
  templateUrl: './social-signup-buttons.component.html',
  styleUrls: ['./social-signup-buttons.component.css'],
})
export class SocialSignupButtonsComponent implements OnInit, OnDestroy {
  user: any = {};
  loggedIn: any;
  returnUrl: any;
  socialSubscription: any;
  actionType: string = 'Login';
  constructor(
    private apiService: ApiService,
    private sharedService: SharedService,
    private authService: SocialAuthService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    if (!this.router.url.includes('login')) {
      this.actionType = 'Signup';
    }
    this.returnUrl = this.activeRoute.snapshot.queryParams['returnUrl'] || '/';
    this.socialSubscription = this.authService.authState.subscribe((user) => {
      if (user != null && Object.keys(this.user).length === 0) {
        this.user = user;
        this.checkUserExistance();
      }
      this.loggedIn = user != null;
    });
  }

  ngOnDestroy() {
    this.authService.signOut(true);
    this.user = {};
    this.socialSubscription.unsubscribe();
  }

  signInWithFB(): void {
    // if (document.location.hostname == 'localhost') {
    //   this.sharedService.featureNotSupportedOnLocalhost();
    //   return;
    // }
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  loginWithGoogle(objsignup?: any) {
    this.apiService
      .postRequest(URLConfig.loginWithGoogle, {
        access_token: this.user.response.access_token,
      })
      .subscribe(
        (data: any) => {
          let obj = {
            token: data.access_token,
            user: data.user,
          };
          this.userLoggedIn(obj, objsignup);
        },
        (error: any) => {
          this.authService.signOut(true);
          this.user = {};
          if (error.status == 400) {
            let err: any = Object.entries(error.error);
            this.sharedService.showHideToast({
              type: 'error',
              message: err[0][1][0],
              title: 'Something Went Wrong',
            });
          }
        }
      );
  }

  userLoggedIn(data: any, objsignup?: any) {
    localStorage.setItem('token', data.token);
    if (objsignup == 'signup') {
      var result = {
        image: this.user.photoUrl,
        full_name: this.user.firstName + ' ' + this.user.lastName,
      };
      this.updateUserInfoOnSignup(result);
      // this.sendEmailAndReferredBy();
    } else {
      this.getUserDetails();
    }
    this.router.navigateByUrl(this.returnUrl);
  }

  getUserDetails() {
    this.apiService.getRequest(URLConfig.profile).subscribe((data: any) => {
      localStorage.setItem('user', JSON.stringify(data));
      localStorage.setItem('actualUserType', data.type);
      this.sharedService.updateUser(data);
      this.sharedService.userAuthenticated(true);
      this.sharedService.updateUserAttributes();
    });
  }

  loginWithFb(objsignup?: any) {
    this.apiService
      .postRequest(URLConfig.loginWithFacebook, {
        access_token: this.user.authToken,
      })
      .subscribe(
        (data: any) => {
          let obj = {
            token: data.access_token,
            user: data.user,
          };
          this.userLoggedIn(obj, objsignup);
        },
        (error: any) => {
          this.authService.signOut(true);
          this.user = {};
          if (error.status == 400) {
            let err: any = Object.entries(error.error);
            this.sharedService.showHideToast({
              type: 'error',
              message: err[0][1][0],
              title: 'Something Went Wrong',
            });
          }
        }
      );
  }

  signInWithGoogle(): void {
    // if (document.location.hostname == 'localhost') {
    //   this.sharedService.featureNotSupportedOnLocalhost();
    //   return;
    // }
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  signInWithLinkedin() {
    if (document.location.hostname == 'localhost') {
      this.sharedService.featureNotSupportedOnLocalhost();
      return;
    }
    this.sharedService.featureNotSupported();
  }

  openTermsAndConditionsModal(provider: any) {
    // const modalRef = this.modalService.open(TermsAndConditionsModalComponent, {
    //   centered: true,
    //   scrollable: true,
    //   backdrop: 'static',
    //   keyboard: false,
    //   windowClass: 'signUpModalMain',
    // });

    // modalRef.result.then((result) => {
    //   if (result == 'accepted') {
    //     this.openBetaVersionModal(provider);
    //   } else {
    //     this.authService.signOut(true);
    //     this.user = {};
    //   }
    // });
    this.openBetaVersionModal(provider);
  }

  openBetaVersionModal(provider: any) {
    const modalRef = this.modalService.open(BetaVersionModalComponent, {
      centered: true,
      scrollable: true,
      windowClass: 'signUpModalMain',
    });

    modalRef.result.then((result) => {
      if (result == 'true') {
        if (provider == 'GOOGLE') {
          this.loginWithGoogle('signup');
        }
        if (provider == 'FACEBOOK') {
          this.loginWithFb('signup');
        }
      } else {
        this.authService.signOut(true);
        this.user = {};
      }
    });
  }
  checkUserExistance() {
    this.apiService
      .postRequest(URLConfig.userExist, { user_email: this.user.email })
      .subscribe((data: any) => {
        if (data == true) {
          if (this.user.provider == 'GOOGLE') {
            this.loginWithGoogle();
          }
          if (this.user.provider == 'FACEBOOK') {
            this.loginWithFb();
          }
        } else {
          this.openTermsAndConditionsModal(this.user.provider);
        }
      });
  }
  updateUserInfoOnSignup(result: any) {
    this.apiService
      .patchRequest(URLConfig.profile, result)
      .subscribe((data: any) => {
        this.sharedService.updateUser(data);
        this.getUserDetails();
      });
  }

  sendEmailAndReferredBy() {
    const modalRef = this.modalService.open(SendEmailReferredByModalComponent, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.result.then((result) => {
      this.updateUserInfoOnSignup(result);
      this.getUserDetails();
      this.sharedService.updateUserAttributes();
    });
  }
}
