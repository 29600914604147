import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { URLConfig } from 'src/app/configs/url-config';
import { CommonFunctionUtils } from 'src/app/utils/common-functions-utils';
import { BehaviorSubject, interval } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RatingModalComponent } from '../../dashboard/my-works/Modals/rating-modal/rating-modal.component';
import { PusherService } from 'src/app/services/pusher.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notifications: any = [];
  unreadNotificationCount: any = 0;
  notificationInterval: any;
  notificationPollingInterval = environment.notificationPollingInterval;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private sharedService: SharedService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getNotifications();
    this.getUnreadNotificationCount();
    this.notificationInterval = setInterval(() => {
      this.getNotifications();
      this.getUnreadNotificationCount();
    }, this.notificationPollingInterval);
  }

  ngOnDestroy(): void {
    clearInterval(this.notificationInterval);
  }

  markAllAsRead() {
    this.apiService
      .postRequest(URLConfig.markAllAsRead, {})
      .subscribe((data: any) => {
        this.getNotifications();
        this.getUnreadNotificationCount();
      });
  }

  getNotifications(): void {
    let params = {
      page_size: 20,
    };
    this.apiService
      .getRequestWithParams(URLConfig.notifications, params)
      .subscribe((data: any) => {
        this.notifications = data.results;
        console.log(this.notifications);

        this.notifications.sort((a: any, b: any) => {
          return a.created_at < b.created_at ? 1 : -1;
        });
      });
  }

  getUnreadNotificationCount(): void {
    this.apiService
      .getRequest(URLConfig.unreadNotificationCount)
      .subscribe((data: any) => {
        this.unreadNotificationCount = data;
      });
  }

  getReadAbleTime(time: any) {
    return CommonFunctionUtils.timeFromNow(time);
  }

  markNotificationAsRead(noti_id: any) {
    let url = URLConfig.markNotificationAsRead + noti_id;
    this.apiService.postRequest(url, {}).subscribe((data: any) => {
      this.getNotifications();
      this.getUnreadNotificationCount();
    });
  }

  openRatingModal(noti: any) {
    const modalRef = this.modalService.open(RatingModalComponent, {
      centered: true,
      scrollable: true,
    });
    modalRef.componentInstance.work_id = noti.object_id;
    modalRef.componentInstance.advisor = noti.sender;
  }

  openNotification(notification: any) {
    if (notification.action_type == 'open_advisor_finished_work_list') {
      this.router.navigate(['/dashboard/my-works/list/finished']);
    }
    if (notification.action_type == 'rate_advisor') {
      this.openRatingModal(notification);
      this.markNotificationAsRead(notification.id);

      //****Use this if you want to open the work detail as well****
      // this.sharedService.openWorkFromNotifications(true);
      // this.router.navigate(
      //   [
      //     'dashboard/my-works/reviewed-user-work-detail/' +
      //       notification.object_id
      //   ],
      //   {
      //     queryParams: {
      //       rate_advisor: true,
      //       advisor: notification.sender.id
      //     }
      //   }
      // );
    }
    if (notification.action_type == 'open_advisor_work') {
      this.sharedService.openWorkFromNotifications(true);
      this.router.navigate(['/advisor-work-detail/' + notification.object_id], {
        queryParams: {
          open_advisor_work: true,
        },
      });
    }
    if (notification.action_type == 'work_response') {
      this.sharedService.openWorkFromNotifications(true);
      if (notification?.page_number) {
        let page = notification?.page_number.replace(/'/g, '"');
        this.sharedService.openAnnoFromNotification.next({
          page_number: JSON.parse(page)?.page_number,
          anno_ids: JSON.parse(page)?.anno_ids,
        });
      }
      if (
        notification?.notification_content?.match(
          'just addressed your comment; check it out.'
        )
      ) {
        this.router.navigateByUrl('/user-work-detail/' + notification.work_id);
      } else {
        this.router.navigate(['/advisor-work-detail/' + notification.work_id], {
          queryParams: {
            open_advisor_work: true,
          },
        });

        // this.sharedService.annoId.next()
      }

      if (notification.action_type == 'open_finished_user_work') {
        this.sharedService.openWorkFromNotifications(true);
        this.router.navigate(
          ['reviewed-user-work-detail/' + notification.object_id],
          {
            queryParams: {
              advisor: notification.sender.id,
            },
          }
        );
      }
    }
  }
}
