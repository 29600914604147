import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-first-work-upload',
  templateUrl: './first-work-upload.component.html',
  styleUrls: ['./first-work-upload.component.css']
})
export class FirstWorkUploadComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
