<!-- <div class="quickStatusWrap">
  <label>
    <input
      type="radio"
      name="online"
      id="online"
      value="online"
      [(ngModel)]="userDetails.advisor_status"
      (ngModelChange)="updateAvailability($event)"
    />
    <span>
      Quick Response<img
        src="../../../../../assets/quick-green.svg"
        height=""
        width=""
        alt="logo" /><img
        src="../../../../../assets/quick-white.svg"
        height=""
        width=""
        alt="logo"
    /></span>
  </label>
  <label>
    <input
      type="radio"
      name="available"
      id="available"
      value="available"
      [(ngModel)]="userDetails.advisor_status"
      (ngModelChange)="updateAvailability($event)"
    />
    <span>Accept requests</span></label
  >
  <label>
    <input
      type="radio"
      name="unavailable"
      id="unavailable"
      value="unavailable"
      [(ngModel)]="userDetails.advisor_status"
      (ngModelChange)="updateAvailability($event)"
    /><span>Unavailable</span></label
  >
</div> -->

<div class="quickFeed quickFeedWeb">
  <p>
    I'll give you feedback within:
    <span
      >{{ quick_feedback }}
      <img src="../../../../../assets/online.svg" height="" width="" alt="logo"
    /></span>
  </p>
  <a
    href="javascript:;"
    [routerLink]="['/dashboard/account-settings/availability']"
    ><img
      src="../../../../../assets/feedback-edit.svg"
      height=""
      width=""
      alt="logo"
  /></a>
</div>
<div class="quickFeed quickFeedMob">
  <p>
    Feedback within:
    <span
      >{{ quick_feedback }}
      <img src="../../../../../assets/online.svg" height="" width="" alt="logo"
    /></span>
  </p>
  <a
    href="javascript:;"
    [routerLink]="['/dashboard/account-settings/availability']"
    ><img
      src="../../../../../assets/feedback-edit.svg"
      height=""
      width=""
      alt="logo"
  /></a>
</div>
