<div class="modalWrap termsModal">
    <div>
        <h2>Deadline settings</h2>
    </div>
    <div class="content">
        <p>
            You must complete the request within the specified "Estimated delivery time" from when the request is made. There is no approval process for requests.
        </p>
    </div>
    <div class="btn-group text-center">
        <!-- <button type="button" class="button heightBtn whiteBtn" (click)="activeModal.close('cancel')">Cancel</button> -->
        <button type="button" class="button heightBtn greenBtn" (click)="activeModal.close('true')">Got It</button>
    </div>
</div>
<button type="button" class="close" aria-label="Close" (click)="activeModal.close('cancel')">
    <img src="../../../../../assets/sign-exit-grey.svg" height="" width="" alt="sign-exit">
</button>