<button
  type="button"
  class="close"
  aria-label="Close"
  (click)="activeModal.dismiss('Cross click')"
>
  <img
    src="../../../../../assets/sign-exit-grey.svg"
    height=""
    width=""
    alt="sign-exit"
  />
</button>
<div class="modalWrap termsModal">
  <h2>Rate the advisor</h2>

  <div class="rateAdDp">
    <img
      [src]="advisor.image"
      onError="this.src='../../../../../assets/profile-avatar.jpeg'"
      height=""
      width=""
      alt="name"
    />
    <p>{{ advisor.full_name }}</p>
  </div>
  <label>Rate your experience</label>
  <bar-rating
    [readOnly]="already_reviewed"
    class="ratingInput"
    [(rate)]="form.rating"
    [max]="5"
  ></bar-rating>
  <label>Text</label>
  <div class="inputWrap">
    <textarea
      [disabled]="already_reviewed"
      [(ngModel)]="form.message"
      name="message"
      class="form-control"
    ></textarea>
  </div>
  <ng-container *ngIf="advisorRequest.request_for_example && !already_reviewed">
    <label>Message from advisor</label>
    <div class="inputWrap">
      <textarea
        disabled
        [(ngModel)]="advisorRequest.message"
        name="message"
        class="form-control"
      ></textarea>
    </div>
  </ng-container>
  <!-- <div *ngIf="!already_shared" class="inputWrap">
    <label class="checkboxWrap lgLabel" for="rateCheck"
      ><input
        [disabled]="already_reviewed"
        [(ngModel)]="form.object_to_share"
        type="checkbox"
        name="object_to_share"
        class="form-check-input"
        id="rateCheck"
      />I object to sharing this work and related advice with the community.
    </label>
  </div> -->

  <div class="btn-group">
    <button
      type="button"
      class="button heightBtn whiteBtn"
      (click)="activeModal.close('Close click')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="button heightBtn greenBtn"
      (click)="submitReview()"
    >
      Save
    </button>
  </div>
</div>
