export enum Packages {
  STANDARD = 'STANDARD',
  INTERACTIVE = 'INTERACTIVE',
  IN_DEPTH = 'IN_DEPTH',
}

export enum UserTypes {
  NEW = 'NEW',
  EXPERIENCED = 'EXPERIENCED',
  PRIME = 'PRIME',
  NORMAL = 'NORMAL',
}
