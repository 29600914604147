<div class="modalWrap termsModal notLoggedModal">
    <div>
      <h2>It looks like you are not logged in</h2>
    </div>
    <div class="content">
      <p>Please log in to see your Favorite</p>
    </div>
    <div class="btn-group">
      <button type="button" class="button heightBtn whiteBtn" (click)="activeModal.close('Close click')">Cancel</button>
      <button type="button" class="button heightBtn greenBtn" (click)="activeModal.close('Close click')" routerLink="/auth/login">Log in</button>
     
    </div>
    <button type="button" class="button transBtn">No account yet?</button>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <img src="../../../../../assets/sign-exit-grey.svg" height="" width="" alt="sign-exit">
  </button>
  