import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-advice',
  templateUrl: './advice.component.html',
  styleUrls: ['./advice.component.css'],
})
export class AdviceComponent implements OnInit {
  @Input() subcategories: any;
  constructor(private route: Router) {}

  ngOnChanges() {}

  ngOnInit(): void {}

  fatchExamples(sub_category_id: any) {
    this.route.navigate(['examples/category/examples/' + sub_category_id]);
  }
}
