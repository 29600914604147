import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { URLConfig } from 'src/app/configs/url-config';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-advisor-status',
  templateUrl: './advisor-status.component.html',
  styleUrls: ['./advisor-status.component.css'],
})
export class AdvisorStatusComponent implements OnInit {
  userDetails: any = JSON.parse(localStorage.getItem('user') || '{}');
  availability: any;
  quick_feedback: any = null;
  steps: any = [
    { value: '1h', due_time: 60 },
    { value: '3h', due_time: 180 },
    { value: '1d', due_time: 1440 },
    { value: '3d', due_time: 4320 },
    { value: '1w', due_time: 10080 },
    { value: '2w', due_time: 20160
 },
    { value: 'Unavailable', due_time: -1 }

  ];

  constructor(
    private apiService: ApiService,
    private sharedService: SharedService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.sharedService.availibilty.subscribe(res => this.quick_feedback = res)
    this.getAdvisorAvailability();

  }

  getAdvisorAvailability() {
    this.apiService
      .getRequest(URLConfig.advisorAvailability)
      .subscribe((data: any) => {
        this.availability = data;
        let availability:any={};
        availability['quick_feedback'] = data['quick_feedback']
        availability['interactive_feedback'] = data['interactive_feedback']
        availability['long_feedback'] = data['long_feedback']
        let minAvailablityKey = Object.keys(availability).reduce((key, v) => (availability[v] < availability[key] && availability[v] > 0)? v : key)
        let minAvailablityValue = availability[minAvailablityKey];      
        this.minutesToIndex(minAvailablityValue)
      });
  }
  minutesToIndex(minutes: number) {
    this.quick_feedback = this.steps.find((x: any) => x.due_time === minutes)?.value || 0;
  }

}
