import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { NotLoggedModalComponent } from '../../modals/not-logged-modal/not-logged-modal.component';
import { URLConfig } from 'src/app/configs/url-config';
import { Store } from '@ngxs/store';
import {
  GetAdvisors,
  GetFavoriteAdvisors,
} from 'src/app/stores/actions/advisor.actions';
import { UserTypes } from 'src/app/utils/app.enums';

@Component({
  selector: 'app-advisor-card',
  templateUrl: './advisor-card.component.html',
  styleUrls: ['./advisor-card.component.css'],
})
export class AdvisorCardComponent implements OnInit, OnChanges {
  @Input('advisors') advisors: any;
  @Input('queryParams') queryParams: any;
  @Input('showPriceTags') showPriceTags: any;
  @Input('selectedAdvisorsAndPackages') selectedAdvisorsAndPackages: any;
  @Input() buttonText = 'Visit';

  @Output() advisorSelected = new EventEmitter<any>();
  @Output() favouriteAdvisor = new EventEmitter<any>();
  authenticated: Boolean = false;
  isAuthenticatedSubscription: any;
  already_reviewed: boolean = true;
  userDetails: any;
  windowWidth: number = window.innerWidth;
  userTypes = UserTypes;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private apiService: ApiService,
    private _store: Store
  ) {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {
    this.isAuthenticatedSubscription =
      this.sharedService.authenticated.subscribe((value) => {
        this.authenticated = value;
        this.userDetails = JSON.parse(localStorage.getItem('user') || '{}');
      });

    window.addEventListener('resize', (e: UIEvent) => {
      const w = e.target as Window;
      this.windowWidth = w.innerWidth;
    });
  }

  updateWindowWidth() {
    this.windowWidth = window.innerWidth;
  }

  ngOnDestroy() {
    this.isAuthenticatedSubscription.unsubscribe();
  }

  markAsFavourite(advisor: any) {
    if (this.authenticated) {
      const payload = {
        advisor: advisor.id,
        isFavourite: !advisor.isFavourite,
      };
      this.apiService
        .postRequest(URLConfig.favouriteAdvisor, payload)
        .subscribe((data: any) => {
          advisor.isFavourite = payload.isFavourite;
          this._store.dispatch(new GetAdvisors(this.queryParams));
          this._store.dispatch(new GetFavoriteAdvisors());

          this.favouriteAdvisor.emit(advisor);
          this.sharedService.showHideToast({
            type: 'success',
            message: data.message,
            title: 'Success',
          });
        });
    } else {
      const modalRef = this.modalService.open(NotLoggedModalComponent, {
        centered: true,
        scrollable: true,
      });
    }
  }
  visitProfile(advisor: any) {
    // this.router.navigate(['/advisor/' + advisor_id], {
    //   queryParams: this.queryParams
    // });
    advisor.isSelected = !advisor.isSelected;
    this.advisorSelected.emit(advisor);
    // this.router.navigate(['/advisor/' + advisor.advisor_id]);
  }

  visitProfileinMobile(advisor: any) {
    if (this.windowWidth <= 766) {
      this.visitProfile(advisor);
    }
  }

  isSelected(id: any) {
    let isSelected = false;
    this.selectedAdvisorsAndPackages?.forEach((advisor: any) => {
      if (advisor?.id == id) {
        isSelected = true;
      }
    });
    return isSelected;
  }
}
