import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
@Component({
  selector: 'app-price-filter',
  templateUrl: './price-filter.component.html',
  styleUrls: ['./price-filter.component.css'],
})
export class PriceFilterComponent implements OnInit, OnChanges {
  // lowValue: number = 0;
  // highValue: number = 100;
  value: number = 0;
  priceOptions: Options = {
    floor: 0,
    ceil: 50,
    showSelectionBar: true,
    translate: (value: number): string => {
      return '$ ' + value;
    },
  };

  @Output() priceChanged = new EventEmitter<any>();
  @Input() priceConfig: any;
  @Input() type: any;
  @Input() infoBox: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.value = this.priceConfig.priceValue;

    switch (this.type) {
      case 'interactive':
        this.priceOptions.ceil = 250;
        break;
      case 'in_depth':
        this.priceOptions.ceil = 1000;
        break;
      default:
        this.priceOptions.ceil = 50;
        break;
    }
  }

  sliderChanged(event: any) {
    this.priceChanged.emit(this.value);
  }
}
