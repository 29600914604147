<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
  <img src="../../../../../assets/sign-exit-grey.svg" height="" width="" alt="sign-exit">
</button>
<div class="modalWrap termsModal">
  <h2>Your saved forms will be deleted! Are you sure you want to log out?</h2>
  <div class="btn-group">
    <button type="button" class="button heightBtn whiteBtn" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="button" class="button heightBtn greenBtn" (click)="logout(); activeModal.close()">Log out</button>
  </div>
</div>
