<div class="archiveExamples">
  <img
    src="../../../../../assets/archive-example.svg"
    height=""
    width=""
    alt="name"
  />
  <p *ngIf="category != null" class="text-dark">
    Example not found with {{ category?.name }}
  </p>
  <p
    *ngIf="category == null"
    [ngClass]="isHomePage ? 'text-white' : 'text-dark'"
  >
    {{ isExampleLoaded ? "Example not found" : "Loading Examples" }}
  </p>
</div>
