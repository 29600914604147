import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  getRequest(url: any) {
    return this.http.get(url);
  }

  getRequestPromise(url: any): Promise<any> {
    return this.http.get(url).toPromise();
  }

  async getReqAsPromise(url: any) {
    return await this.http
      .get(url, { responseType: 'blob' as 'json' })
      .toPromise();
  }

  getRequestWithParams(url: any, params: any) {
    return this.http.get(url, { params: params });
  }

  async getRequestWithParamsPromise<T>(url: any, params: any): Promise<T> {
    return await this.http.get<T>(url, { params: params }).toPromise();
  }

  postRequest(url: any, dataModel: any) {
    return this.http.post(url, dataModel);
  }

  async postRequestAsPromise<T>(url: any, dataModel: any): Promise<T> {
    return await this.http.post<T>(url, dataModel).toPromise();
  }

  patchRequest(url: any, dataModel: any) {
    return this.http.patch(url, dataModel);
  }

  async verifyToken(url: any, dataModel: any) {
    return await this.http.post(url, dataModel)?.toPromise();
  }

  deleteRequest(url: any) {
    return this.http.delete(url);
  }
}
