import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { URLConfig } from 'src/app/configs/url-config';
import { CategoryStateModel } from '../models/state.models';
import { Injectable } from '@angular/core';
import { GetCategories, ResetCategoryState } from '../actions/category.actions';

@State<CategoryStateModel>({
  name: 'categories',
  defaults: {
    categories: [],
    categoriesLoaded: false,
  },
})
@Injectable()
export class CategoryState {
  constructor(private apiService: ApiService) {}

  @Selector()
  static getCategory(state: CategoryStateModel) {
    return state.categories;
  }

  @Selector()
  static isLoaded(state: CategoryStateModel) {
    return state.categoriesLoaded;
  }

  @Action(GetCategories)
  async fetchUserPrograms({
    getState,
    setState,
  }: StateContext<CategoryStateModel>) {
    await this.apiService
      .getRequestPromise(URLConfig.allExperties)
      .then((res) => {
        const state = getState();
        setState({
          ...state,
          categories: res,
          categoriesLoaded: true,
        });
      });
  }

  @Action(ResetCategoryState)
  async resetCategoryState({
    getState,
    setState,
  }: StateContext<CategoryStateModel>) {
    const state = getState();
    setState({
      ...state,
      categories: [],
      categoriesLoaded: false,
    });
  }
}
