import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-header-base',
  templateUrl: './header-base.component.html',
  styleUrls: ['./header-base.component.css']
})
export class HeaderBaseComponent implements OnInit {

  authenticated: Boolean = false;
  isAuthenticatedSubscription: any;

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.isAuthenticatedSubscription = this.sharedService.authenticated.subscribe(value => {      
      this.authenticated = value
    });    
  }

  ngOnDestroy() {
    this.isAuthenticatedSubscription.unsubscribe();
  }

}
