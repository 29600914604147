<div class="dicoverAdFlex" style="margin-bottom: 20px">
  <!--  <div class="discoverAdBox">
        <div class="dpWrap">
            <img src="../../../../assets/advisor-dp.svg" height="" width="" alt="dp">
        </div>
        <h6>Haylie Ekstrom Bothman</h6>
        <div class="rating">
            <span>5.0</span>
            <span>50 Coins</span>
        </div>
    </div> -->
  <ng-container *ngFor="let advisor of advisors">
    <div (click)="visitProfile(advisor.id)" [ngClass]="{'advisorSelected': isSelected(advisor.id)}" class="discoverAdBox smDiscoverAdBox">
      <div class="discoverAdBoxTop">
        <div>
          <div class="dpWrap">
            <!-- <img
              [src]="advisor.image"
              onError="this.src='../../../../../assets/profile-avatar.jpeg'"
              height=""
              width=""
              alt="dp"
            /> -->
            <app-profile-picture [userDetails]="advisor"></app-profile-picture>
            <img
              src="../../../../assets/advisor-badge.svg"
              height=""
              width=""
              alt="dp"
              class="badgeIcon"
            />
          </div>
        </div>
        <label class="SelectedLabel" *ngIf="isSelected(advisor.id)">Selected</label>
        <button class="btn"></button>
      </div>
      <h4>{{ advisor.full_name }}</h4>
      <h5>{{ advisor.job_title_experience.job_title }}</h5>
      <p>
        {{ advisor.job_title_experience.years_of_experience }} years of
        Experience
      </p>
      <div class="advisorFigures">
        <div>
          <h6>Rank</h6>
          <h6>
            5.0 (56)
            <img
              src="../../../../assets/star.svg"
              height=""
              width=""
              alt="star"
            />
          </h6>
        </div>
        <div>
          <h6>Examples</h6>
          <h6><span>0</span></h6>
        </div>
      </div>
      <button
        (click)="visitProfile(advisor.id)"
        class="button shortBtn whiteBtn"
      >
        Visit
      </button>
    </div>
  </ng-container>
</div>
