<img
  src="{{ image ? showImage : '/assets/profile-avatar.jpeg' }}"
  height=""
  width=""
  alt="dp"
/>
<!-- 
<img
  *ngIf="!image"
  onError="this.src='../../../../..'"
  height=""
  width=""
  alt="dp"
/> -->
