<div class="modalWrap termsModal">
    <div>
        <h2>Beta version of Oprovi</h2>
    </div>
    <div class="content">
        <p>Welcome to the Oprovi (beta) website. We are excited to share with you our website which we hope will help you create better content. We are currently in <span style="font-weight: 700;text-decoration:underline">beta testing</span> mode before we officially release the website.  </p><br>
            <p><span style="font-weight: 800;text-decoration:underline">Disclaimer.</span> This beta-website, its software and all content found on it are provided on an “as is” and “as available” basis. We do not give any warranties, whether express or implied, as to the suitability or usability of the website, its software or any of its content. We will not be liable for any loss, whether such loss is direct, indirect, special or consequential, suffered by any party as a result of their use of the Oprovi beta-website, its software or content. Any downloading or uploading of material to the beta-website is done at your own risk and you will be solely responsible for any damage to any computer system or loss of data that results from such activities.</p>
            <br>
            <p>If you encounter any bugs, glitches, lack of functionality or other problems on the beta-website, please let us know immediately so we can fix these and improve your user experience. Your help is much appreciated.
            </p>
                <p><span style="font-weight: 800;text-decoration:underline">Intellectual Property.</span> You may only upload content that you own or have the right to license onto the Oprovi website. In the event that we receive an infringement claim with respect to the content you uploaded we will act in accordance with applicable law, including with respect to any notice and takedown procedure. 
            </p>
            <br>
                    <p>You hereby forever and irrevocably grant us, the creators, developers and owners of Oprovi, a perpetual right to (or authorise others to) reproduce, display, distribute, exhibit and exploit your content in whole or in part (all as may be edited for the purpose of the website) in connection with the website as well as any and all intellectual property rights therein, throughout the world, in any medium now known or hereinafter developed, for any commercial and/or non-commercial purposes related to the website.</p><br>
            <p style="margin-bottom:5px;"><span style="font-weight: 800;text-decoration:underline">Personal Information.</span> If you upload your content onto the beta-website, please note that your content will then be viewable by other users in various capacities as well as third-parties who are helping us create this website. By uploading your content to the website you are expressing your explicit consent to our use of the content and the personal information therein for purposes of developing and marketing the website.</p>
            <p style="font-size:10px;">[We will not share your personal information with third-parties, other than: <span style="font-weight: 600;">(i) :</span> With our service providers for the performance of the services they provide to us, such as producing the website; and/or <span style="font-weight: 600;">(ii) :</span> If we are required to disclose your information by a judicial, governmental or regulatory authority.<br>
            You have the right to <span style="font-weight: 800;text-decoration:underline">withdraw your consent</span> at any time. Please contact us at info@oprovi.com should you wish to do so. If you withdraw your consent, we will discontinue using your personal information going forward, but the withdrawal will not affect prior uses we already made based on your consent before the withdrawal. You have the right to have us <span style="font-weight: 800;text-decoration:underline">erase</span> the personal information if you withdraw your consent. However, such deletion will not affect prior uses of the personal information we already made based on your consent, before the withdrawal. Following the withdrawal of your consent, we will also keep a record of your personal information, the original consent and its withdrawal for recordkeeping purposes and defense of legal claims, on the basis of our legal obligations of complying with accountability requirements. You also have a right to have us erase your personal information if the processing is determined to be <span style="font-weight: 800;text-decoration:underline">unlawful</span>, or if we no longer need the personal information for the purposes explained in this Agreement.]
        </p><br>
            <p>No remuneration. You acknowledge that your participation in beta-testing the website is voluntary and that you will not receive financial compensation of any type associated with the uploading of your content onto the website or for your participation in beta-testing. You hereby release us, the creators, developers, and owners of Oprovi, from any obligation to compensate or remunerate you in any form with respect to said consent, release and/or grant.
            </p><br><br>
            <p><span style="font-weight: 800;text-decoration:underline">Release.</span> You hereby release and hold harmless us, the creators, developers and owners of Oprovi, our contractors, and any third-parties involved in the creation, publication, maintenance and/or funding of the website from (i) any reasonable expectation of privacy or confidentiality associated with the website; (ii) liability resulting from any third-party intellectual property infringement claims, and/or (iii) liability for any claims in connection with your participation in beta-testing this website, whether claimed by you or a third-party.
            </p><br>
    </div>
    <div class="btn-group text-center">
        <button type="button" class="button heightBtn whiteBtn" (click)="activeModal.close('cancel')">Cancel</button>
        <button type="button" class="button heightBtn greenBtn" (click)="activeModal.close('true')">Accept</button>
    </div>
</div>
<button type="button" class="close" aria-label="Close" (click)="activeModal.close('cancel')">
    <img src="../../../../../assets/sign-exit-grey.svg" height="" width="" alt="sign-exit">
</button>