import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AdvisorStateModel } from '../models/state.models';
import { Injectable } from '@angular/core';
import {
  GetAdvisors,
  GetAdvisorsAndPackages,
  GetFavoriteAdvisors,
  GetUserPrograms,
  ResetState,
  UpdateAdvisors,
} from '../actions/advisor.actions';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { URLConfig } from 'src/app/configs/url-config';
import { tap } from 'rxjs/operators';
import { IAdvisor } from 'src/app/utils/types';
import { environment } from 'src/environments/environment';

@State<AdvisorStateModel>({
  name: 'advisors',
  defaults: {
    advisors: [],
    advisorsAndPackages: [],
    loaded: false,
    advisorAndPackagesLoaded: false,
    favoriteAdvisors: [],
    favoriteAdvisorLoaded: false,
    userPrograms: [],
    userProgramsLoaded: false,
  },
})
@Injectable()
export class AdvisorState {
  constructor(
    private apiService: ApiService,
    private sharedService: SharedService
  ) {}

  @Selector()
  static getAdvisors(state: AdvisorStateModel) {
    return state.advisors;
  }

  @Selector()
  static isLoaded(state: AdvisorStateModel) {
    return state.loaded;
  }

  @Selector()
  static getAdvisorsAndPackages(state: AdvisorStateModel) {
    return state.advisorsAndPackages;
  }

  @Selector()
  static isAdvisorAndPackagesLoaded(state: AdvisorStateModel) {
    return state.advisorAndPackagesLoaded;
  }

  @Selector()
  static getFavoriteAdvisors(state: AdvisorStateModel) {
    return state.favoriteAdvisors;
  }

  @Selector()
  static isFavoriteAdvisorsLoaded(state: AdvisorStateModel) {
    return state.favoriteAdvisorLoaded;
  }

  @Selector()
  static getUserPrograms(state: AdvisorStateModel) {
    return state.userPrograms;
  }

  @Selector()
  static isUserProgramsLoaded(state: AdvisorStateModel) {
    return state.userProgramsLoaded;
  }

  @Action(GetAdvisors)
  async fetchAdvisors(
    { getState, setState }: StateContext<AdvisorStateModel>,
    { params }: GetAdvisors
  ) {
    await this.apiService
      .getRequestWithParamsPromise<IAdvisor[]>(
        URLConfig.discoverAdvisors,
        params
      )
      .then((res) => {
        const state = getState();
        let advisors = res;
        advisors.map((advisor: any) => {
          if (advisor.program_logo) {
            advisor.program_logo =
              environment.baseUrl + advisor.program_logo.slice(1);
          }
          return advisor;
        });
        advisors = advisors.map((advisor: any) => {
          const isFavourite = state.favoriteAdvisors.find(
            (f: any) => f.username === advisor.username
          );
          return { ...advisor, isFavourite: isFavourite };
        });
        setState({
          ...state,
          advisors: advisors,
          loaded: true,
        });
      });
  }

  @Action(GetAdvisorsAndPackages)
  async fetchAdvisorsAndPackages(
    { getState, setState }: StateContext<AdvisorStateModel>,
    { params }: GetAdvisorsAndPackages
  ) {
    await this.apiService
      .getRequestWithParamsPromise<IAdvisor[]>(
        URLConfig.discoverAdvisors,
        params
      )
      .then((res) => {
        const state = getState();
        setState({
          ...state,
          advisorsAndPackages: res,
          advisorAndPackagesLoaded: true,
        });
      });
  }

  @Action(GetFavoriteAdvisors)
  async fetchFavoriteAdvisors({
    getState,
    setState,
  }: StateContext<AdvisorStateModel>) {
    await this.apiService
      .getRequestPromise(URLConfig.favouriteAdvisor)
      .then((res) => {
        const state = getState();
        setState({
          ...state,
          favoriteAdvisors: res.message == 'No advisors found' ? [] : res,
          favoriteAdvisorLoaded: true,
        });
      });
  }

  @Action(GetUserPrograms)
  async fetchUserPrograms({
    getState,
    setState,
  }: StateContext<AdvisorStateModel>) {
    await this.apiService
      .getRequestPromise(URLConfig.fetchUserPrograms)
      .then((res) => {
        const state = getState();
        setState({
          ...state,
          userPrograms: res,
          userProgramsLoaded: true,
        });
      });
  }

  @Action(ResetState)
  async resetState({ getState, setState }: StateContext<AdvisorStateModel>) {
    const state = getState();
    setState({
      ...state,
      advisors: [],
      advisorsAndPackages: [],
      loaded: false,
      advisorAndPackagesLoaded: false,
      favoriteAdvisors: [],
      favoriteAdvisorLoaded: false,
      userPrograms: [],
      userProgramsLoaded: false,
    });
  }

  @Action(UpdateAdvisors)
  async updateAdvisors({
    getState,
    setState,
  }: StateContext<AdvisorStateModel>) {
    const state = getState();
    setState({
      ...state,
      advisors: state.advisors.map((advisor) => {
        const isFavourite = state.favoriteAdvisors.find(
          (f: any) => f.username === advisor.username
        );
        return { ...advisor, isFavourite: isFavourite };
      }),
      favoriteAdvisorLoaded: true,
    });
  }
}
