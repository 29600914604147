<button
  type="button"
  class="close"
  aria-label="Close"
  (click)="activeModal.close(false)"
>
  <img
    src="../../../../../assets/sign-exit-grey.svg"
    height=""
    width=""
    alt="sign-exit"
  />
</button>
<div class="modalWrap termsModal addCatModal">
  <h2>Add New Category</h2>
  <label>Choose Feedback Category</label>

  <div class="addpaymentFlex">
    <div class="inputWrap">
      <div *ngIf="adding_new_category" class="categoryStrip">
        <ng-select
          class="ngSelect"
          (change)="categoryChanged($event)"
          name="feedback_category"
          required
          #feedback_category
        >
          <!-- <ng-option
            *ngFor="let category of filteredCategories"
            [value]="category"
            >{{ category.name }}</ng-option
          > -->
          <ng-container *ngFor="let category of filteredCategories">
            <ng-option
              *ngIf="category.sub_category.length > 0"
              [value]="category"
              >{{ category.name }}
            </ng-option>
          </ng-container>
        </ng-select>
        <p
          *ngIf="formSubmitted && selectedSubcategories.length == 0"
          class="errorP"
        >
          Please select feedback category
        </p>
      </div>
      <div
        *ngIf="selectedSubcategories.length > 0 && adding_new_category"
        class="categoryStrip"
      >
        <label>Choose Feedback Subcategory</label>

        <ng-select
          class="ngSelect"
          (change)="subcategoryChanged($event)"
          name="feedback_subcategory"
          required
          #feedback_subcategory
        >
          <ng-option
            *ngFor="let subcategory of selectedSubcategories"
            [value]="subcategory"
            >{{ subcategory.name }}</ng-option
          >
        </ng-select>

        <!-- feedback subCatg checkbox list sec  -->
        <!-- <div class="subcatListSec">
                <div  class="sidebarBox mt-3">
                 
                  <label  for="fbPost" title="Facebook Post" class="checkboxWrap "><input  type="checkbox" id="fbPost" class="form-check-input"><span >Facebook Post</span></label>
                  <label  for="Story" title="Story" class="checkboxWrap "><input  type="checkbox" id="Story" class="form-check-input"><span >Story</span></label>
                  <label  for="Whatsapp-SMS" title="Whatsapp-SMS" class="checkboxWrap "><input  type="checkbox" id="Whatsapp-SMS" class="form-check-input"><span >Whatsapp-SMS</span></label>
                  <label  for="Other" title="Other" class="checkboxWrap "><input  type="checkbox" id="Other" class="form-check-input"><span >Other</span></label>
                </div>
              </div> -->
        <!-- feedback subCatg checkbox list sec end // -->

        <p
          *ngIf="formSubmitted && selectedSubcategories.length > 0"
          class="errorP"
        >
          Please select feedback subcategory
        </p>
      </div>
    </div>
  </div>
  <div class="btn-group">
    <button
      type="button"
      class="button heightBtn whiteBtn"
      (click)="activeModal.close(false)"
    >
      Cancel
    </button>
    <button
      type="button"
      class="button heightBtn greenBtn"
      (click)="saveCategory()"
    >
      Add
    </button>
  </div>
</div>
