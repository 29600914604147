<section class="feedbackSection">
  <img
    src="../../../../assets/feedback-bg.svg"
    height=""
    width=""
    alt="feedback-bg"
  />
  <div>
    <img
      src="../../../../assets/feedback-vector.svg"
      height=""
      width=""
      alt="feedback-vector"
    />
    <div>
      <h2>Professional advice helps to create professional content</h2>
      <p class="lgP">
        Oprovi is a marketplace where you can purchase professional feedback on
        your digital content:
      </p>
      <div class="btnGroup">
        <ng-container
          *ngFor="let item of subcategories.slice(0, 10); let i = index"
        >
          <a [routerLink]="'/examples'" href="javascript:;">{{ item.name }}</a>
        </ng-container>
      </div>
    </div>
    <button class="button greenBtn heightBtn" routerLink="/upload-work">
      Let's start
    </button>
  </div>
</section>
