import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-not-logged-modal',
  templateUrl: './not-logged-modal.component.html',
  styleUrls: ['./not-logged-modal.component.css']
})
export class NotLoggedModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

}
