import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvisorCardComponent } from './advisor-card/advisor-card.component';
import { HeaderLoggedInComponent } from '../common-components/header-logged-in/header-logged-in.component';
import { AdvisorStatusComponent } from '../common-components/advisor-status/advisor-status.component';
import { HeaderLoggedOutComponent } from '../common-components/header-logged-out/header-logged-out.component';
import { PriceFilterComponent } from './price-filter/price-filter.component';
import { DueTimeFilterComponent } from './due-time-filter/due-time-filter.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AdvisorCardSmallComponent } from './advisor-card-small/advisor-card-small.component';
import { NoAdvisorComponent } from './no-advisor/no-advisor.component';
import { FooterComponent } from '../common-components/footer/footer.component';
import { AdviceComponent } from '../common-components/advice/advice.component';
import { HeaderBaseComponent } from './../common-components/header-base/header-base.component';
import { RouterModule } from '@angular/router';
import { ExampleCardComponent } from './example-card/example-card.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { ExitButtonComponent } from './exit-button/exit-button.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountdownModule } from 'ngx-countdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatStepperModule } from '@angular/material/stepper';
import { DropdownModule } from 'primeng/dropdown';
import { NoExamplesFoundComponent } from './no-examples-found/no-examples-found.component';
import { NoSharedExamplesFoundComponent } from './no-shared-examples-found/no-shared-examples-found.component';
import { SharedExampleListComponent } from './shared-example-list/shared-example-list.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SpinnerComponent } from './spinner/spinner.component';
import { LogoutModalComponent } from '../dashboard/account-settings/Modals/logout-modal/logout-modal.component';
import { BarRatingModule } from 'ngx-bar-rating';
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import { NotificationsComponent } from '../common-components/notifications/notifications.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ClipboardModule } from 'ngx-clipboard';
import { FormsModule } from '@angular/forms';
import { ProfilePictureComponent } from '../common-components/profile-picture/profile-picture.component';
import { MyProfilePictureComponent } from '../common-components/my-profile-picture/my-profile-picture.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SocialSignupButtonsComponent } from '../auth/signup/social-signup-buttons/social-signup-buttons.component';
import { ExampleFiltersComponent } from './example-filters/example-filters.component';
import { ExampleSearchFilterComponent } from './example-search-filter/example-search-filter.component';
import { MaxWorkFilterComponent } from './max-work-filter/max-work-filter.component';
import { DiscoverAdvisorAvailibiltyFilterComponent } from './discover-advisor-availibilty-filter/discover-advisor-availibilty-filter.component';
import { AddCategoryModalComponent } from '../dashboard/account-settings/Modals/add-category-modal/add-category-modal.component';
import { LoaderComponent } from './loader/loader.component';
import { SortPipe } from './pipes/sort.pipe';

@NgModule({
  declarations: [
    AdvisorCardComponent,
    HeaderLoggedInComponent,
    AdvisorStatusComponent,
    HeaderLoggedOutComponent,
    PriceFilterComponent,
    DueTimeFilterComponent,
    AdvisorCardSmallComponent,
    NoAdvisorComponent,
    FooterComponent,
    AdviceComponent,
    HeaderBaseComponent,
    ExampleCardComponent,
    ExitButtonComponent,
    NoExamplesFoundComponent,
    NoSharedExamplesFoundComponent,
    SharedExampleListComponent,
    SpinnerComponent,
    LogoutModalComponent,
    AddCategoryModalComponent,
    CustomToastComponent,
    NotificationsComponent,
    ConfirmationModalComponent,
    ProfilePictureComponent,
    MyProfilePictureComponent,
    SocialSignupButtonsComponent,
    ExampleFiltersComponent,
    ExampleSearchFilterComponent,
    MaxWorkFilterComponent,
    DiscoverAdvisorAvailibiltyFilterComponent,
    LoaderComponent,
    SortPipe,
  ],
  imports: [
    CommonModule,
    NgxSliderModule,
    RouterModule,
    NgxPaginationModule,
    IvyCarouselModule,
    SidebarModule,
    ButtonModule,
    NgbModule,
    CountdownModule,
    NgSelectModule,
    MatStepperModule,
    DropdownModule,
    SlickCarouselModule,
    BarRatingModule,
    NgxWebstorageModule.forRoot(),
    ClipboardModule,
    FormsModule,
  ],
  exports: [
    AdvisorCardComponent,
    HeaderLoggedInComponent,
    AdvisorStatusComponent,
    HeaderLoggedOutComponent,
    PriceFilterComponent,
    DueTimeFilterComponent,
    AdvisorCardSmallComponent,
    NoAdvisorComponent,
    FooterComponent,
    AdviceComponent,
    HeaderBaseComponent,
    ExampleCardComponent,
    NgxPaginationModule,
    IvyCarouselModule,
    SidebarModule,
    ButtonModule,
    ExitButtonComponent,
    NgbModule,
    CountdownModule,
    NgSelectModule,
    MatStepperModule,
    DropdownModule,
    NoExamplesFoundComponent,
    NoSharedExamplesFoundComponent,
    SharedExampleListComponent,
    SlickCarouselModule,
    LogoutModalComponent,
    AddCategoryModalComponent,
    BarRatingModule,
    CustomToastComponent,
    NotificationsComponent,
    ConfirmationModalComponent,
    ProfilePictureComponent,
    MyProfilePictureComponent,
    DragDropModule,
    SocialSignupButtonsComponent,
    ExampleFiltersComponent,
    MaxWorkFilterComponent,
    DiscoverAdvisorAvailibiltyFilterComponent,
    LoaderComponent,
    SortPipe,
  ],
})
export class SharedModule {}
