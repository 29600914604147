import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.css']
})
export class LogoutModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {}

  logout() {
    this.sharedService.logout();
  }
}
