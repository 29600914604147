import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import {
  Options,
  LabelType,
  CustomStepDefinition,
} from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-discover-advisor-availibilty-filter',
  templateUrl: './discover-advisor-availibilty-filter.component.html',
  styleUrls: ['./discover-advisor-availibilty-filter.component.css'],
})
export class DiscoverAdvisorAvailibiltyFilterComponent implements OnInit {
  isDueFilter: boolean = true;
  value: any = 0;
  steps: any = [
    // { value: '20m', due_time: 20 },
    // { value: '40m', due_time: 40 },
    { value: '1h', due_time: 60 },
    // { value: '2h', due_time: 120 },
    { value: '3h', due_time: 180 },
    { value: '1d', due_time: 1440 },
    { value: '3d', due_time: 4320 },
    { value: '1w', due_time: 10080 },
    { value: '2w', due_time: 20160 },
  ];
  options: Options = {
    showSelectionBarEnd: true,
    showTicksValues: true,
    showTicks: false,
    stepsArray: this.steps.map((val: any): CustomStepDefinition => {
      return { value: this.arrayToIndex(val) };
    }),
    translate: (value: number, label: LabelType): string => {
      return this.indexToArray(value);
    },
  };

  @Input('configObj') configObj: any;
  @Input('feedbackValue') feedbackValue: any;
  @Output() timeChanged = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  sliderChanged(event: any) {
    this.timeChanged.emit(this.steps[event.value].due_time);
    localStorage.setItem(
      'advisor_availability_filter',
      JSON.stringify(this.steps[event.value])
    );
  }

  arrayToIndex(val: any) {
    return this.steps.indexOf(val);
  }

  indexToArray(index: any) {
    return this.steps[index].value;
  }

  minutesToIndex(minutes: number) {
    this.value = this.steps.findIndex((x: any) => x.due_time === minutes);
  }

  ngOnChanges() {
    if (this.feedbackValue) this.minutesToIndex(this.feedbackValue);
    else this.minutesToIndex(this.configObj?.value);
  }
}
