export class GetAdvisors {
  static readonly type = '[Advisor] Get';
  constructor(public params?: any) {}
}

export class GetAdvisorsAndPackages {
  static readonly type = '[Advisor and Packages] Get';
  constructor(public params: any) {}
}

export class GetFavoriteAdvisors {
  static readonly type = '[Favorite Advisors] Get';
}

export class GetUserPrograms {
  static readonly type = '[User Programs] Get';
}

export class ResetState {
  static readonly type = '[Reset Satate] Get';
}

export class UpdateAdvisors {
  static readonly type = '[Update Advisors] PATCH';
}
