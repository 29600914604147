import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { SharedService } from './services/shared.service';
import { ApiService } from './services/api.service';
import { Select, Store } from '@ngxs/store';
import { BalanceState } from './stores/states/transaction.state';
import { Observable } from 'rxjs';
import { GetBalances } from './stores/actions/transaction.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  showSpinner: any = false;
  toastConfig: any;
  showToast: Boolean = false;
  @Select(BalanceState.isLoaded) balancesLoaded$!: Observable<boolean>;
  @Select(BalanceState.getBalances) balanceList$!: Observable<any[]>;

  constructor(
    private sharedService: SharedService,
    private _cdj: ChangeDetectorRef,
    private _sharedService: SharedService,
    private _store: Store
  ) {}

  ngOnInit() {
    this.sharedService.showSpinner.subscribe((value) => {
      this.showSpinner = value;
    });
    let user = JSON.parse(localStorage.getItem('user')!);
    if (user) {
      this.balancesLoaded$.subscribe((res) => {
        if (!res) {
          this._store.dispatch(new GetBalances());
          this.balanceList$.subscribe((res) => {
            this._sharedService.totalBalance.next(res[0]?.new_balance);
          });
        } else {
          this.balanceList$.subscribe((res) => {
            this._sharedService.totalBalance.next(res[0]?.new_balance);
          });
        }
      });
    }

    this.sharedService.showToast.subscribe((value: any) => {
      this.toastConfig = value;
      this.showToast = true;
      if (
        this.toastConfig.auto_close == undefined ||
        this.toastConfig.auto_close
      ) {
        setTimeout(() => {
          this.showToast = false;
        }, 3000);
      }
    });
    this._cdj.detectChanges();
  }

  closeToast(event: any) {
    if (event) {
      this.showToast = false;
    }
  }

  ngAfterViewInit() {}
}
