import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  private handleErrorResponse(
    err: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    if (err.status === 401 || err.status === 400 || err.status === 403) {
      if (
        err.status === 401 &&
        !err.url?.includes('/work_summary/like/') &&
        request.method !== 'GET'
      ) {
        localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    }
    return throwError(err);
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      !request.url.includes('password_reset') &&
      !request.url.includes('v2.convertapi.com') &&
      !request.url.includes('get_work_annotation') &&
      !request.url.includes('api.sandbox.paypal.com') &&
      !request.url.includes('capture') &&
      !request.url.includes('example/latest') &&
      !request.url.includes('oprovi.s3.eu-central-1.amazonaws')
    ) {
      request = request.clone({
        headers: request.headers.set(
          'Authorization',
          'JWT ' + localStorage.getItem('token')
        ),
      });
    }
    return next
      .handle(request)
      .pipe(catchError((x) => this.handleErrorResponse(x, request, next)));
  }
}
