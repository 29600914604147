<section class="filterWrap mb">
  <div class="searchWrap">
    <input
      type="text"
      name="search"
      [(ngModel)]="searchText"
      class="form-control"
      (input)="fetchAdvisors()"
      (keydown.enter)="fetchAdvisors()"
      placeholder="Ex: Improve Engagement"
    />
    <button class="button heightBtn greenBtn" (click)="fetchAdvisors()">
      <img
        src="../../../../assets/search-white.svg"
        height=""
        width=""
        alt="search"
      />
    </button>
  </div>
  <div class="filterDropMain">
    <div class="filterDrops">
      <!-- <div class="filterDropBox">
                            <label>Sort by</label>

                <div class="dropdown">
                    <button class="button heightBtn dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ selectedCategory?.name || 'All Result'}}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="javascript:;" (click)="updateSelection($event,'all')">All
                            Result</a>
                        <a class="dropdown-item" href="javascript:;" (click)="updateSelection($event,category)"
                            *ngFor="let category of feedBackCategories">{{category.name}}</a>
                    </div>
                </div>
            </div> -->
    </div>
    <div class="filterDrops">
      <div class="filterDropBox">
        <label>Time</label>
        <div class="dropdown">
          <button
            class="button heightBtn dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            disabled
          >
            All
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#">This Year</a>
            <a class="dropdown-item" href="#">This Month</a>
            <a class="dropdown-item" href="#">This Day</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
