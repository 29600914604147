<div class="dropdown notifyDropdown">
  <a
    href="#"
    class="button transBtn"
    type="button"
    (click)="markAllAsRead()"
    id="dropdownMenuButton1"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <img
      src="../../../../../assets/notify-icon.svg"
      height=""
      width=""
      alt="logo"
    />

    <h6 class="notfTextMob">Notifications</h6>

    <span *ngIf="unreadNotificationCount != 0" class="notifyCount">{{
      unreadNotificationCount
    }}</span>
  </a>
  <div
    class="dropdown-menu"
    aria-labelledby="dropdownMenuButton"
    style="z-index: 9999999"
  >
    <div class="notifyDropScroll">
      <ng-container *ngFor="let notification of notifications; let i = index">
        <div class="dropdown-item-wrap">
          <a
            href="javascript:;"
            (click)="openNotification(notification)"
            class="dropdown-item"
          >
            <!-- dropdown-item-----for New
        dropdown-item new------for sticky
        dropdown-item read-----for old not -->
            <img
              [src]="notification.sender.image"
              onError="this.src='../../../../../assets/profile-avatar.jpeg'"
              height=""
              width=""
              alt="logo"
            />
            <div>
              <h6>
                {{ notification.sender.full_name }}
                <span>{{ getReadAbleTime(notification.created_at) }}</span>
              </h6>
              <p>{{ notification.notification_content }}</p>
            </div>
            <label *ngIf="!notification.read" class="new">New</label>
          </a>
        </div>
      </ng-container>
      <button routerLink="/all-notifications" class="btn whiteBtn">
        <img
          src="../../../../../assets/see-notify-icon.svg"
          height=""
          width=""
          alt="logo"
        />See All Notifications
      </button>
    </div>
  </div>
</div>
