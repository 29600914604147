<div class="modalWrap termsModal">
    <div>
        <h2>To get started, Upload your content</h2>
    </div>
    <div class="content">
        <p>
            To get started, Upload your content. This is the first step towards receiving professional feedback that can help take your content and skills to the next level.
        </p>
    </div>
    <div class="btn-group text-center">
        <!-- <button type="button" class="button heightBtn whiteBtn" (click)="activeModal.close('cancel')">Cancel</button> -->
        <button type="button" class="button heightBtn greenBtn" (click)="activeModal.close('true')" >Got it</button>
    </div>
</div>
<button type="button" class="close" aria-label="Close" (click)="activeModal.close('cancel')">
    <img src="../../../../../assets/sign-exit-grey.svg" height="" width="" alt="sign-exit">
</button>