// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'https://dfa2-119-155-45-220.ngrok-free.app/',
  // baseUrl: 'https://backend.oprovi.com/',
  // baseUrl: 'https://mgk2b4px-8001.euw.devtunnels.ms/',
  // baseUrl: 'http://172.206.13.208/',
  baseUrl: 'https://test-backend.oprovi.com/',
  accessKeyId: 'AKIA4HX7JLHVGLAX646Q',
  secretAccessKey: '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',
  paypalCLientId:
    'AS1XyiKU80-1_r7_qLf0n8w5LiESI6soMHMKO3fEYZEf9bHaaxg2s95LPt4Vji79HXtZeetysn1AOLS0',
  notificationPollingInterval: 100000,
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
