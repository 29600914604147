import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges
} from '@angular/core';
import {
  Options,
  LabelType,
  CustomStepDefinition
} from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-max-work-filter',
  templateUrl: './max-work-filter.component.html',
  styleUrls: ['./max-work-filter.component.css']
})
export class MaxWorkFilterComponent implements OnInit {

  value: any = 0;
  steps: any = [
    { value: '1', due_time: 1 },
    { value: '2', due_time: 2 },
    { value: '3', due_time: 3 },
  ];
  options: Options = {
    showSelectionBarEnd: true,
    showTicksValues: true,
    showTicks: false,
    stepsArray: this.steps.map(
      (val: any): CustomStepDefinition => {
        return { value: this.arrayToIndex(val) };
      }
    ),
    translate: (value: number, label: LabelType): string => {
      return this.indexToArray(value);
    }
  };

  @Input('maxWrokValue') maxWrokValue: any;
  @Output() maxWorkChanged = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  sliderChanged(event: any) {
    this.maxWorkChanged.emit(this.steps[event.value].due_time);
  }

  arrayToIndex(val: any) {
    return this.steps.indexOf(val);
  }

  indexToArray(index: any) {
    return this.steps[index].value;
  }

  minutesToIndex(minutes: number) {
    this.value = this.steps.findIndex((x: any) => x.due_time === minutes);
  }

  ngOnChanges() {
    this.minutesToIndex(this.maxWrokValue)
  }

}
