<section class="footer">
  <div class="wrapper">
    <div class="footerFlex">
      <div class="left">
        <a href="#" class="logo"
          ><img
            src="../../../../../assets/footer-logo2.svg"
            height=""
            width=""
            alt="logo"
        /></a>
        <p class="lgP">
          Oprovi - professional feedback marketplace on work-in-progress
        </p>
        <ul class="socialIcons">
          <li>
            <a
              href="https://www.facebook.com/people/Oprovi/100066776064002/"
              target="_blank"
              class="fa fa-facebook-square"
            ></a>
          </li>
          <li>
            <a
              href="#"
              class="fa fa-twitter"
              (click)="sharedService.featureNotSupported()"
            ></a>
          </li>
          <li>
            <a
              href="#"
              class="fa fa-instagram"
              (click)="sharedService.featureNotSupported()"
            ></a>
          </li>
          <li>
            <a href="mailto:service@oprovi.com" class="fa fa-envelope"></a>
          </li>
        </ul>
        <div class="fooContact">
          <h5>Contact Us</h5>
          <p class="lgP">
            <a href="mailto:Info@oprovi.com"
              ><img
                src="../../../../../assets/envelope-o.svg"
                height=""
                width=""
                alt="envelope"
              />Info@oprovi.com</a
            >
          </p>
          <p class="lgP">
            <a href="tel:5055550125"
              ><img
                src="../../../../../assets/phone-o.svg"
                height=""
                width=""
                alt="phone"
              />(505) 555-0125</a
            >
          </p>
          <div class="fooUploadWrap">
            <img
              src="../../../../../assets/green-upload-img.svg"
              height=""
              width=""
              alt="phone"
            />
            <a
              class="btn transBtn"
              href="#"
              (click)="sharedService.featureNotSupported()"
            >
              Upload Work
              <img
                src="../../../../../assets/green-arrow-right.svg"
                height=""
                width=""
                alt="phone"
            /></a>
            <p>and get advice in less than an hour</p>
          </div>
        </div>
      </div>
      <div class="middle">
        <div>
          <h5>Navigation</h5>
          <div>
            <ul>
              <li *ngIf="authenticated">
                <a [routerLink]="'/dashboard/my-works/list/on-going'"
                  >My Content</a
                >
              </li>
              <li><a [routerLink]="'/about-us'">About Us</a></li>
              <li>
                <a [routerLink]="'/terms-and-conditions'"
                  >Terms and Conditions</a
                >
              </li>
              <li><a [routerLink]="'/privacy-policy'">Privacy Policy</a></li>
              <li>
                <a [routerLink]="'/user-data-deletion'">User Data Deletion</a>
              </li>
              <li>
                <a [routerLink]="'/advisor-agreement'">Advisor Agreement</a>
              </li>
              <li><a [routerLink]="'/beta-disclaimer'">Beta Disclaimer</a></li>

              <!-- <li><a href="#">Coins</a></li> -->
              <!-- <li><a [routerLink]="'/become-an-advisor'">Become an
                  Advisor</a></li> -->
            </ul>
          </div>
        </div>

        <div>
          <h5>Discover Examples</h5>
          <ul>
            <ng-container
              *ngFor="let item of feedBackCategories; let i = index"
            >
              <li *ngIf="i < 10">
                <a href="javascript:;" (click)="fatchExamples(item)">{{
                  item.name
                }}</a>
              </li>
            </ng-container>
          </ul>
        </div>
        <div>
          <ul *ngIf="feedBackCategories.length >= 10">
            <ng-container
              *ngFor="let item of feedBackCategories; let i = index"
            >
              <li *ngIf="i >= 10 && i < 20">
                <a href="javascript:;" (click)="fatchExamples(item)">{{
                  item.name
                }}</a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
      <div class="right fooContact">
        <h5>Contact Us</h5>
        <p class="lgP">
          <a href="mailto:Info@oprovi.com"
            ><img
              src="../../../../../assets/envelope-o.svg"
              height=""
              width=""
              alt="envelope"
            />Info@oprovi.com</a
          >
        </p>
        <p class="lgP">
          <a href="tel:5055550125"
            ><img
              src="../../../../../assets/phone-o.svg"
              height=""
              width=""
              alt="phone"
            />+972.58.653.0341</a
          >
        </p>
        <div class="fooUploadWrap">
          <img
            src="../../../../../assets/green-upload-img.svg"
            height=""
            width=""
            alt="phone"
          />
          <a [routerLink]="'/upload-work'" class="btn transBtn"
            >Upload Work<img
              src="../../../../../assets/green-arrow-right.svg"
              height=""
              width=""
              alt="phone"
          /></a>
          <p>and get advice in less than an hour</p>
        </div>
      </div>
    </div>
  </div>
  <div class="footerBottom">
    <div class="wrapper">
      <div class="footerFlex">
        <p>&copy; 2023 Oprovi. All right reserved.</p>
        <ul>
          <li><a href="#" routerLink="/privacy-policy">Privacy Policy</a></li>
          <li>
            <a href="#" routerLink="/terms-and-conditions"
              >Terms and Conditions</a
            >
          </li>
          <li><a [routerLink]="'/beta-disclaimer'">Beta Disclaimer</a></li>
        </ul>
      </div>
    </div>
  </div>
</section>
