<div class="modalWrap termsModal ">
    <div>
       <h2>Don't get attached to one advisor, Add more advisors to get a better perspective. </h2>
   </div>
   <div class="content">
       <p>
        <img
             class="icon mr-2"
             src="../../../../../assets/quoteImg.png"
             height=""
             width=""
             alt="name"
           /> None of us is as smart as all of us
       </p>
       <span class="quoteAuthor"> Ken Blanchard
       </span>
   </div>
   <div class="btn-group text-center">
       <!-- <button type="button" class="button heightBtn whiteBtn" (click)="activeModal.close('cancel')">Cancel</button> -->
       <button type="button" class="button heightBtn greenBtn" (click)="activeModal.close('true')">Got It</button>
   </div>
</div>
<button type="button" class="close" aria-label="Close" (click)="activeModal.close('cancel')">
   <img src="../../../../../assets/sign-exit-grey.svg" height="" width="" alt="sign-exit">
</button>