<div class="priceSlider customSlider advSlider">
  <ngx-slider
    [(value)]="value"
    [options]="priceOptions"
    (userChangeEnd)="sliderChanged($event)" 
  ></ngx-slider>
  <!-- <div class="popUpSec" *ngIf="infoBox">
      <div  class="infoBo"><p><img src="../../../../../assets/green-info.svg">Max value for new advisor is 10$ </p></div>
  </div> -->
</div>



