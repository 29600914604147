import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-advisor-card-small',
  templateUrl: './advisor-card-small.component.html',
  styleUrls: ['./advisor-card-small.component.css']
})
export class AdvisorCardSmallComponent implements OnInit {
  @Input('advisors') advisors: any;
  @Input('selectedAdvisors') selectedAdvisors: any;
  @Input('queryParams') queryParams: any;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  isSelected(advisor_id: any) {
    if (this.selectedAdvisors) {
      let isPresent = this.selectedAdvisors.find(function(el: any) {
        return el.id === advisor_id;
      });
      if (isPresent) {
        return true;
      }
      return false;
    }
    return false;
  }

  visitProfile(advisor_id: any) {
    this.router.navigate(['/advisor/' + advisor_id], {
      queryParams: this.queryParams
    });
  }
}
