import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortImages',
})
export class SortPipe implements PipeTransform {
  transform(array: any[]): any {
    console.log(array);

    if (array.length == 0) {
      return array;
    }
    return array.sort((a, b) => {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    });
  }
}
