import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { URLConfig } from '../configs/url-config';
import { Router } from '@angular/router';
import {
  SocialAuthService,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WelcomeModal2Component } from '../pages/base/modals/welcome-modal2/welcome-modal2.component';
import { WelcomeModalComponent } from '../pages/base/modals/welcome-modal/welcome-modal.component';
import { FirstWorkUpload2Component } from '../pages/base/modals/first-work-upload2/first-work-upload2.component';
import { FirstWorkUploadComponent } from '../pages/base/modals/first-work-upload/first-work-upload.component';
import { FirstTimeSelectAdvisorModalComponent } from '../pages/base/modals/first-time-select-advisor-modal/first-time-select-advisor-modal.component';
import { FirstSetupAvailablityModalComponent } from '../pages/base/modals/first-setup-availablity-modal/first-setup-availablity-modal.component';
import { FirstWorkModalComponent } from '../pages/base/dashboard/my-works/Modals/first-work-modal/first-work-modal.component';
import { FirstWorkModal2Component } from '../pages/base/dashboard/my-works/Modals/first-work-modal2/first-work-modal2.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { ResetState } from '../stores/actions/advisor.actions';
import { ResetCategoryState } from '../stores/actions/category.actions';
import { ResetTransactionState } from '../stores/actions/transaction.actions';

export interface IOpenAnnoFromNotification {
  page_number: number;
  anno_ids: string[];
}

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  favouriteAdvisors: any = [];

  private isAuthenticated = new BehaviorSubject(false);
  authenticated = this.isAuthenticated.asObservable();

  private stepNumber = new BehaviorSubject(0);
  uploadContentStep = this.stepNumber.asObservable();

  private setSelectedAdvisorsAndPackages = new BehaviorSubject(false);
  getSelectedAdvisorsAndPackages =
    this.setSelectedAdvisorsAndPackages.asObservable();

  private breadCrumbs = new BehaviorSubject([]);
  breadCrumbsArray = this.breadCrumbs.asObservable();

  private spinner = new BehaviorSubject(false);
  showSpinner = this.spinner.asObservable();

  private toast = new BehaviorSubject({ auto_close: false });
  showToast = this.toast.asObservable();

  private work = new BehaviorSubject(false);
  openWork = this.work.asObservable();

  private user = new BehaviorSubject(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  userDetail = this.user.asObservable();

  isExampleLoaded = new BehaviorSubject<boolean | null>(false);

  workDetailsPage = new BehaviorSubject<number | null>(null);
  openAnnoFromNotification =
    new BehaviorSubject<IOpenAnnoFromNotification | null>(null);

  totalBalance = new BehaviorSubject<number | null>(null);

  constructor(
    private apiService: ApiService,
    private router: Router,
    private authService: SocialAuthService,
    private location: Location,
    private modalService: NgbModal,
    private _store: Store,
    private _apiService: ApiService
  ) {
    this.checkToken();
    // this.checkUserDetail();
  }

  async checkToken() {
    if (!localStorage.getItem('token')) {
      this.isAuthenticated.next(false);
      return;
    }
    let res: any = await this.apiService.verifyToken(URLConfig.verifyToken, {
      token: localStorage.getItem('token'),
    });
    if (res.token_verified) {
      this.isAuthenticated.next(true);
    }
  }

  steps: any = [
    { value: '1h', due_time: 60 },
    { value: '3h', due_time: 180 },
    { value: '1d', due_time: 1440 },
    { value: '3d', due_time: 4320 },
    { value: '1w', due_time: 10080 },
    { value: '2w', due_time: 20160 },
    { value: 'Unavailable', due_time: -1 },
  ];

  private userAvailibilty = new BehaviorSubject({});
  availibilty = this.userAvailibilty.asObservable();

  updateUserAvailibilty(value: any) {
    let availability: any = {};
    availability['quick_feedback'] = value['quick_feedback'];
    availability['interactive_feedback'] = value['interactive_feedback'];
    availability['long_feedback'] = value['long_feedback'];

    let minAvailablityKey = Object.keys(availability).reduce((key, v) =>
      availability[v] < availability[key] && availability[v] > 0 ? v : key
    );
    let minAvailablityValue = availability[minAvailablityKey];

    this.userAvailibilty.next(
      this.steps.find((x: any) => x.due_time === minAvailablityValue).value
    );
  }

  updateUser(obj: any) {
    this.user.next(obj);
    localStorage.setItem('user', JSON.stringify(obj));
  }

  userAuthenticated(value: any) {
    this.isAuthenticated.next(value);
  }

  updateStepNumber(value: any) {
    this.stepNumber.next(value);
  }

  updateBreadCrumbs(value: any) {
    this.breadCrumbs.next(value);
  }

  showHideSpinner(value: any) {
    this.spinner.next(value);
  }

  showHideToast(value: any) {
    this.toast.next(value);
  }

  openWorkFromNotifications(value: any) {
    this.work.next(value);
  }

  updateSelectedAdvisorsAndPackages(obj: any) {
    this.setSelectedAdvisorsAndPackages.next(obj);
  }

  updateUserAttributes(options?: any) {
    this.showHideSpinner(true);
    this.apiService.getRequest(URLConfig.profile).subscribe((data: any) => {
      setTimeout(() => {
        if (
          data.user_attributes == null ||
          data.user_attributes.is_new_user == false
        ) {
          this.openWelcomeModal();
        }

        let first_work_upload = JSON.parse(
          localStorage.getItem('first_work_upload_modal') || 'false'
        );

        if (
          first_work_upload == false &&
          options?.uploadContent == true &&
          data.user_attributes != null &&
          data.user_attributes.first_time_content_upload == false
        ) {
          this.openFirstWorkUploadModal();
        }

        let first_advisor_select_modal = JSON.parse(
          localStorage.getItem('first_advisor_select_modal') || 'false'
        );

        if (
          first_advisor_select_modal == false &&
          options?.backToStep2 == true &&
          data.user_attributes != null &&
          data.user_attributes.first_time_advisor_select == false
        ) {
          localStorage.setItem('first_advisor_select_modal', 'true');
          this.openFirstTimeSelectAdvisorModal();
        }

        if (
          options?.update_availabilty == true &&
          data.user_attributes != null &&
          data.user_attributes.advisor_account_availability == false
        ) {
          // localStorage.setItem('first_advisor_select_modal','true');
          this.openFirstTimeSetupAvailabilityModal();
        }

        let signup_availabiltyModal = JSON.parse(
          localStorage.getItem('signup_availabilty') || 'false'
        );

        if (
          options?.signup_availabilty == true &&
          signup_availabiltyModal == false &&
          data.user_attributes != null &&
          data.user_attributes.advisor_signup_availability == false
        ) {
          localStorage.setItem('signup_availabilty', 'true');
          this.openFirstTimeSetupAvailabilityModal();
        }

        if (
          options?.first_work_recevice == true &&
          data.user_attributes != null &&
          data.user_attributes.first_time_advisor_recieve_work == false
        ) {
          this.openFirstWorkmodal();
        }
      }, 5000);
      this.showHideSpinner(false);
    });
  }

  async logout() {
    this.authService.signOut(true);
    let user = JSON.parse(localStorage.getItem('user')!);
    this.userAuthenticated(false);
    this._store.dispatch(new ResetState());
    this._store.dispatch(new ResetCategoryState());
    this._store.dispatch(new ResetTransactionState());
    await this._apiService
      .postRequestAsPromise(URLConfig.logoutApi, {
        id: user?.id,
        type: user?.type,
      })
      .then(() => {
        sessionStorage.clear();
        localStorage.clear();
      });

    this.router.navigate(['/auth/login']);
  }

  featureNotSupported() {
    this.showHideToast({
      type: 'warning',
      message: 'This feature is not supported yet',
      title: 'Feature in Progress',
    });
  }

  featureNotSupportedOnLocalhost() {
    this.showHideToast({
      type: 'warning',
      message: 'This feature is not supported on localhost',
      title: 'Localhost not supported',
    });
  }

  seeAllAdvisors() {
    window.scrollTo(0, 0);
    this.router.navigate(['/discover-advisors']);
  }

  seeAllExamples() {
    window.scrollTo(0, 0);
    this.router.navigate(['/examples']);
  }

  redirectToBack() {
    this.location.back();
  }

  fetchFavouriteAdvisors() {
    this.apiService
      .getRequest(URLConfig.favouriteAdvisor)
      .subscribe((data: any) => {
        if (data.length > 0) {
          this.favouriteAdvisors = data.map((advisor: any) => {
            return { ...advisor, isFavourite: true };
          });
        }
      });
    return this.favouriteAdvisors;
  }

  markAsFavourite(advisor: any) {
    const payload = {
      advisor: advisor.id,
      isFavourite: !advisor.isFavourite,
    };
    this.apiService
      .postRequest(URLConfig.favouriteAdvisor, payload)
      .subscribe((data: any) => {
        advisor.isFavourite = payload.isFavourite;
        this.showHideToast({
          type: 'success',
          message: data.message,
          title: 'Success',
        });
      });
    return advisor;
  }

  markFavouriteAdvisors(advisor: any) {
    const isFavourite = this.favouriteAdvisors.find(
      (f: any) => f.username === advisor.username
    );
    if (isFavourite) advisor['isFavourite'] = isFavourite;

    return advisor;
  }

  onSendMessage(userId: any) {
    this.apiService
      .postRequest(URLConfig.conversation, { user2: userId })
      .subscribe(
        (data: any) => {
          this.router.navigate([
            '/dashboard/messages',
            { conversation_id: data.id },
          ]);
        },
        (error: any) => {
          if (error.status == 400) {
          }
        }
      );
  }

  openWelcomeModal() {
    const modalRef = this.modalService.open(WelcomeModalComponent, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
    });

    // modalRef.result.then((result) => {
    //   this.openWelcomeModal2();
    // });

    modalRef.result.then((result) => {
      this.apiService
        .postRequest(URLConfig.updateAttribute, { new_user_login: true })
        .subscribe(
          (data) => {
            console.log(data);
          },
          (error) => {
            console.log(error);
          }
        );
    });
  }

  openWelcomeModal2() {
    const modalRef = this.modalService.open(WelcomeModal2Component, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.result.then((result) => {
      this.apiService
        .postRequest(URLConfig.updateAttribute, { new_user_login: true })
        .subscribe(
          (data) => {
            console.log(data);
          },
          (error) => {
            console.log(error);
          }
        );
    });
  }

  openFirstWorkUploadModal() {
    const modalRef = this.modalService.open(FirstWorkUploadComponent, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.result.then((result) => {
      localStorage.setItem('first_work_upload_modal', 'true');
      // this.openFirstWorkUpload2Modal();
    });
  }

  openFirstWorkUpload2Modal() {
    const modalRef = this.modalService.open(FirstWorkUpload2Component, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.result.then((result) => {
      localStorage.setItem('first_work_upload_modal', 'true');
    });
  }

  openFirstTimeSelectAdvisorModal() {
    const modalRef = this.modalService.open(
      FirstTimeSelectAdvisorModalComponent,
      {
        centered: true,
        scrollable: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'advisorSelcModal',
      }
    );

    modalRef.result.then((result) => {
      localStorage.setItem('first_advisor_select_modal', 'true');
    });
  }

  openFirstTimeSetupAvailabilityModal() {
    const modalRef = this.modalService.open(
      FirstSetupAvailablityModalComponent,
      {
        centered: true,
        scrollable: true,
        backdrop: 'static',
        keyboard: false,
      }
    );

    modalRef.result.then((result) => {
      this.apiService
        .postRequest(URLConfig.updateAttribute, {
          advisor_account_availability: true,
        })
        .subscribe(
          (data) => {
            console.log(data);
          },
          (error) => {
            console.log(error);
          }
        );
    });
  }

  openFirstWorkmodal() {
    const modalRef = this.modalService.open(FirstWorkModalComponent, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.result.then(() => {
      this.openFirstWorkmodal2();
    });
  }

  openFirstWorkmodal2() {
    const modalRef = this.modalService.open(FirstWorkModal2Component, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.result.then((result) => {
      this.apiService
        .postRequest(URLConfig.updateAttribute, {
          first_time_advisor_recieve_work: true,
        })
        .subscribe(
          (data) => {
            console.log(data);
          },
          (error) => {
            console.log(error);
          }
        );
    });
  }

  getAdvisorDetails() {
    this.apiService.getRequest(URLConfig.profile).subscribe((data: any) => {
      this.updateUser(data);
    });
  }

  encodeURIComponentCustom(value: string): string {
    return encodeURIComponent(value.replace(/ /g, '-'));
  }

  subtract25Percent(value: number, discount: number | null): number {
    if (isNaN(value) || typeof value !== 'number') {
      throw new Error('Invalid input. Please provide a valid number.');
    }
    let Percent = 0;
    if (discount) {
      Percent = discount == 25 ? value * 0.25 : value * 0.2;
    }

    const result = value - Percent;
    return result;
  }
}
