<section class="header">
  <div class="large-wrapper">
    <div class="headFlex">
      <a href="#" class="logo"><img src="../../../../../assets/oprovi_beta2.svg" height="" width="" alt="logo" /></a>
      <ul class="mainMenu">
        <li>
          <a routerLink="/home" routerLinkActive="active" href="javascript:;">Home</a>
        </li>
         <!-- <li>
          <a routerLink="/dashboard" routerLinkActive="active" href="javascript:;">My Works</a>
        </li> -->
        <li>

          <a href="#" routerLink="/about-us">About Us</a>
        </li>
        <li>
          <a routerLink="/discover-advisors" routerLinkActive="active" href="#">Discover Advisors</a>

        </li>
        <li>
          <a routerLink="/examples" routerLinkActive="active" href="#"
            >Examples</a
          >
        </li>
        <!-- <li>
          <a href="#" routerLink="/coins" routerLinkActive="active">Coins</a>
        </li> -->
        <!-- <li>
          <a href="#" routerLink="/become-an-advisor" routerLinkActive="active">Become an Advisor</a>
        </li> -->
      </ul>
      <div>
        <div class="mobileMenu">
          <p-button type="button" (click)="visibleSidebar1 = true"><img
                src="../../../../../assets/mobile-menu.svg"
                height=""
                width=""
                alt="name"
              /></p-button>
          <p-sidebar class="mobileMenuStyle" [(visible)]="visibleSidebar1" [baseZIndex]="10000">
            <ul class="mainMenu">
              <li>
                <a routerLink="/home" routerLinkActive="active" href="javascript:;">Home</a>
              </li>
              <li>

          <a href="#" routerLink="/about-us">About Us</a>
        </li>
              <li>
                <a routerLink="/discover-advisors" routerLinkActive="active" href="#">Discover Advisors</a>
              </li>
              <li>
                <a routerLink="/examples" routerLinkActive="active" href="#">Examples</a>
              </li>
              <!-- <li>
                <a routerLink="/coins" routerLinkActive="active" href="#">Coins</a>
              </li> -->
             
            </ul>
            <div class="headAuth">
              <button routerLink="/auth/signup/normal-user" class="button transBtn">Sign Up</button>
              <button routerLink="/auth/login" (click)="navigateToLogin()" class="button heightBtn greenBtn">Log In</button>
               <a routerLink="/become-an-advisor" routerLinkActive="active" href="#" class="becAnAdvMobMenu">Become an Advisor</a>
            </div>
             
               
             
          
          </p-sidebar>
          
        </div>
        <div class="headAuth desk">
        <button routerLink="/auth/signup/normal-user" class="button transBtn">Sign Up</button>
        <button routerLink="/auth/login" (click)="navigateToLogin()" class="button heightBtn greenBtn">Log In</button>
       
      </div>
      </div>
    </div>
  </div>
</section>
