<button
  type="button"
  class="close"
  aria-label="Close"
  (click)="activeModal.close(false)"
>
  <img
    src="../../../../../assets/sign-exit-grey.svg"
    height=""
    width=""
    alt="sign-exit"
  />
</button>
<div class="modalWrap termsModal">
  <h2>{{heading}}</h2>
  <p>
    {{message}}
  </p>
  <div class="btn-group">
    <button
      type="button"
      class="button heightBtn whiteBtn"
      (click)="activeModal.close(false)"
    >
      No
    </button>
    <button
      type="button"
      class="button heightBtn greenBtn"
      (click)="activeModal.close(true)"
    >
      Yes
    </button>
  </div>
</div>
