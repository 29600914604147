import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.css']
})
export class CustomToastComponent implements OnInit {
  @Input() toastConfig: any;
  @Input() showToast: any;

  @Output() closeToastEvent = new EventEmitter<any>();
  
  constructor() {}

  ngOnInit(): void {}

  closeToast(){
    this.closeToastEvent.emit(true);
  }
}
