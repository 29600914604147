<div class="modalWrap termsModal">
    <div>
        <h2>Welcome to Oprovi!</h2>
    </div>
    <div class="content">
        <p>
            We're happy to have you here. 
            To ensure you have a great first impression, we're offering a discount on feedback from our top advisors.
            These advisors change daily to provide you with a range of different perspectives.  
        </p>
        <br>
        <p>
            Look for the prime icon <img src="../../../../../assets/prime.svg" height="" width="" alt="dp"
            class="badgeIcon" />
        </p>
    </div>
    <div class="btn-group text-center">
        <!-- <button type="button" class="button heightBtn whiteBtn" (click)="activeModal.close('cancel')">Cancel</button> -->
        <button type="button" class="button heightBtn greenBtn" (click)="activeModal.close('true')">Got It</button>
    </div>
</div>
<button type="button" class="close" aria-label="Close" (click)="activeModal.close('cancel')">
    <img src="../../../../../assets/sign-exit-grey.svg" height="" width="" alt="sign-exit">
</button>