import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { URLConfig } from 'src/app/configs/url-config';
import { APIUrlUtils } from 'src/app/utils/api-url-utils';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-rating-modal',
  templateUrl: './rating-modal.component.html',
  styleUrls: ['./rating-modal.component.css']
})
export class RatingModalComponent implements OnInit {
  @Input() work_id: any;
  @Input() advisor: any;
  advisorRequest: any = {};
  form: any = {
    work_id: 0,
    advisor_id: 0,
    rating: 0,
    // message: '',
    // object_to_share: false
  };
  already_reviewed: boolean = false;
  already_shared: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private sharedService: SharedService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getReview();
    this.checkIfWorkAlreadyShared();
  }

  submitReview() {
    this.form.work_id = this.work_id;
    this.form.advisor_id = this.advisor.id;

    this.apiService.postRequest(URLConfig.advisorRating, this.form).subscribe(
      (res: any) => {
        // this.toastr.success(res.message);
        this.sharedService.showHideToast({
          type: 'success',
          message: res.message,
          title: 'Success!'
        });
        this.activeModal.close();
      },
      (error: any) => {
        // this.toastr.error(error.error.message);
        this.sharedService.showHideToast({
          type: 'error',
          message: error.error.message,
          title: 'Something Went Wrong'
        });
        this.activeModal.close();
      }
    );
  }

  // getAdvisorRequest() {
  //   let url = APIUrlUtils.fetchAdvisorRequestURL(this.work_id, this.advisor.id);
  //   this.apiService.getRequest(url).subscribe((data: any) => {
  //     this.advisorRequest = data;
  //   });
  // }

  checkIfWorkAlreadyShared() {
    let url = URLConfig.checkExample + '/' + this.work_id;
    this.apiService.getRequest(url).subscribe((data: any) => {
      this.already_shared = data;
    });
  }

  getReview() {
    let url = APIUrlUtils.fetchAdvisorRatingURL(this.work_id, this.advisor.id);
    this.apiService.getRequest(url).subscribe((data: any) => {
      this.already_reviewed = true;
      this.form = data;
    });
  }
}
