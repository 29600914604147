import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { URLConfig } from './configs/url-config';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { SharedModule } from './pages/base/shared/shared.module';
import { APP_BASE_HREF } from '@angular/common';

// import { NgxImageCompressService } from 'ngx-image-compress';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AdvisorState } from './stores/states/advisor.state';
import { CategoryState } from './stores/states/category.state';
import { BalanceState } from './stores/states/transaction.state';

const googleLoginOptions = {
  scope: 'email',
  plugin_name: 'sample_login', //this can be any string
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    NgbModule, // ToastrModule added
    SharedModule,
    SocialLoginModule,
    NgxsModule.forRoot([AdvisorState, CategoryState, BalanceState]),
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  providers: [
    URLConfig,
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              // '561514155405-0doavnovo99ji5uagjegcov5dgmh4fn5.apps.googleusercontent.com',
              // '1026885253645-cngpj7bcf87f59ark9fpo43bmouu6lm6.apps.googleusercontent.com'
              '1039707022951-iesjopmj5793a2i0irqnshai6fngsd9a.apps.googleusercontent.com', //client
              googleLoginOptions
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              // '694639171994279', // Naeem
              // '405250282270460' // Khubaib
              '510992130707119' // Hadad
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    { provide: APP_BASE_HREF, useValue: '/' },
    // NgxImageCompressService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
