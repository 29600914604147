import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import { LogoutModalComponent } from '../../dashboard/account-settings/Modals/logout-modal/logout-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PusherService } from 'src/app/services/pusher.service';
import { URLConfig } from 'src/app/configs/url-config';
import { ApiService } from 'src/app/services/api.service';
import { FirstWorkUploadComponent } from '../../modals/first-work-upload/first-work-upload.component';

@Component({
  selector: 'app-header-logged-in',
  templateUrl: './header-logged-in.component.html',
  styleUrls: ['./header-logged-in.component.css'],
})
export class HeaderLoggedInComponent implements OnInit {
  visibleSidebar1: any;
  userDetails: any = {};
  userSubscription: any;
  actualUserType: any = localStorage.getItem('actualUserType');
  userType = JSON.parse(localStorage.getItem('user')!)?.type;
  myWorksNotificationCount: any = 0;
  isUploadWorkPage = false;
  dashboardData: any;
  userDetail = JSON.parse(localStorage.getItem('user') || '{}');
  totalBalance: number = 0;
  constructor(
    private primengConfig: PrimeNGConfig,
    private sharedService: SharedService,
    private router: Router,
    private modalService: NgbModal,
    private pusherService: PusherService,
    private apiService: ApiService,
    private _shared: SharedService
  ) {}

  noOnchanges() {}
  ngOnInit(): void {
    this.getDashboardAnalytics();
    this.primengConfig.ripple = true;
    this.userSubscription = this.sharedService.userDetail.subscribe(
      (value: any) => {
        this.userDetails = value;
      }
    );

    this._shared.totalBalance.subscribe((res) => {
      this.totalBalance = res!;
    });

    this.myWorksNotificationCount = JSON.parse(
      localStorage.getItem('myWorksNotificationCount')!
    );

    const channel = this.pusherService.pusher.subscribe(
      'notification_channel-' + this.userDetails.id
    );
    channel.bind('my_works_new_notification_count', (data: any) => {
      this.myWorksNotificationCount = data.count;
      if (data.count > 0) {
        localStorage.setItem(
          'myWorksNotificationCount',
          JSON.stringify(this.myWorksNotificationCount)
        );
      }
    });
    this.isUploadWorkPage = this.router.url.includes('upload-work');
  }

  logout() {
    const modalRef = this.modalService.open(LogoutModalComponent, {
      centered: true,
      scrollable: true,
    });
  }

  navigateToMyWorks(route: any) {
    this.myWorksNotificationCount = 0;
    localStorage.removeItem('myWorksNotificationCount');
    this.resetLastSeen();
    // this.redirectTo('/dashboard/my-works/list/'+ route);
    this.router.navigate(['/dashboard/my-works/list/' + route]);
  }

  getDashboardAnalytics() {
    this.sharedService.showHideSpinner(true);
    let finalUrl = URLConfig.dashboardDetails;
    let params = {
      user_type: 'ADVISOR',
    };
    this.apiService
      .getRequestWithParams(finalUrl, params)
      .subscribe((data: any) => {
        this.dashboardData = data;
        this.sharedService.showHideSpinner(false);
      });
  }

  resetLastSeen() {
    this.apiService
      .postRequest(URLConfig.resetLastSeen, {})
      .subscribe((data: any) => {});
  }

  switchAccount() {
    this.userDetails.type =
      this.userDetails.type == 'ADVISOR' ? 'NORMAL' : 'ADVISOR';

    this.sharedService.updateUser(this.userDetails);
    this.router.navigate([
      this.userDetails.type == 'ADVISOR' ? '/home/advisor' : '/home/user',
    ]);
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }
}
