<!-- warning state toast -->
<div *ngIf="showToast && toastConfig.type == 'warning'" class="toastWrap">
  <button class="closeToast" (click)="closeToast()">
    <img
      src="this.src='../../../../../assets/close-toast.svg"
      height=""
      width=""
      alt="dp"
    />
  </button>
  <div>
    <img
      src="this.src='../../../../../assets/warning-toast.svg"
      class="toast-icon"
      alt="dp"
    />
    <p class="lgP" style="font-weight: 500; color: #f39237; margin-right: 20px">
      {{ toastConfig.title }}
    </p>
    <p class="lgP">{{ toastConfig.message }}</p>
  </div>
</div>

<div *ngIf="showToast && toastConfig.type == 'info'" class="toastWrap">
  <button class="closeToast" (click)="closeToast()">
    <img
      src="this.src='../../../../../assets/close-toast.svg"
      height=""
      width=""
      alt="dp"
    />
  </button>
  <div>
    <img src="/assets/info.svg" class="toast-icon" alt="dp" />
    <p class="lgP" style="font-weight: 500; color: #007bff; margin-right: 20px">
      {{ toastConfig.title }}
    </p>
    <p class="lgP">{{ toastConfig.message }}</p>
  </div>
</div>

<!-- success toast -->
<div *ngIf="showToast && toastConfig.type == 'success'" class="toastWrap">
  <button class="closeToast" (click)="closeToast()">
    <img
      src="this.src='../../../../../assets/close-toast.svg"
      height=""
      width=""
      alt="dp"
    />
  </button>
  <div>
    <img
      src="this.src='../../../../../assets/congrats-toast.svg"
      class="toast-icon"
      alt="dp"
    />
    <p class="lgP" style="font-weight: 500; color: #15a3ac; margin-right: 20px">
      {{ toastConfig.title }}
    </p>
    <p class="lgP">{{ toastConfig.message }}</p>
  </div>
</div>

<!-- wrong toast -->
<div *ngIf="showToast && toastConfig.type == 'error'" class="toastWrap">
  <button class="closeToast" (click)="closeToast()">
    <img
      src="this.src='../../../../../assets/close-toast.svg"
      height=""
      width=""
      alt="dp"
    />
  </button>
  <div>
    <img
      src="this.src='../../../../../assets/wrong-toast.svg"
      height=""
      width=""
      alt="dp"
    />
    <p class="lgP" style="font-weight: 500; color: #840032; margin-right: 20px">
      {{ toastConfig.title }}
    </p>
    <p class="lgP">{{ toastConfig.message }}</p>
  </div>
</div>

<!-- Resume toast -->
<!-- <div class="toastWrap">
  <button class="closeToast"><img src="this.src='../../../../../assets/close-toast.svg" height="" width="" alt="dp" /></button>
 <div>
  <div>
    <p class="lgP">Your resume has been sent. You can undo this action</p>
  </div>
  <div>
   <button class="button heightBtn whiteBtn"> 
      Undo
    </button>
  </div>
</div>
</div> -->

<!-- message toast -->
<!-- <div class="toastWrap">
  <button class="closeToast">
    <img src="this.src='../../../../../assets/close-toast.svg" height="" width="" alt="dp" /></button>
  <div>
    <div style="display: inline-flex; align-items:center; justify-content: flex-start;">
    <img src="this.src='../../../../../assets/msg-toast.svg" height="" width="" alt="dp" />
    <p class="lgP">This message was sent to the Snackbar!</p>
  </div>
  <div>
    <button class="button whiteBtn heightBtn">Undo</button>
    <button class="button transBtn">Go to Messages</button>
  </div>
</div>
</div> -->
<!-- publish toast -->
<!-- <div class="toastWrap">
  <button class="closeToast">
    <img src="../../../../../assets/close-toast.svg" height="" width="" alt="dp" /></button>
  <div>
    <div style="display: inline-flex; align-items:center; justify-content: flex-start;">
    <img src="../../../../../assets/publish1-toast.svg" height="" width="" alt="dp" />
    <p class="lgP">Publish your changes to your team library</p>
  </div>
  <div>
    <button class="button whiteBtn heightBtn">Publish</button>
    <button class="button transBtn redBtn">Dismiss</button>
  </div>
</div>
</div> -->
<!-- congratulation toast -->
<!-- <div class="toastWrap">
  <button class="closeToast">
    <img src="../../../../../assets/close-toast.svg" height="" width="" alt="dp" /></button>
  <div>
    <div style="display: inline-flex; align-items:center; justify-content: flex-start;">
    <img src="../../../../../assets/congrats-toast.svg" height="" width="" alt="dp" />
    <p class="lgP toastHead" style="font-weight: 500; color: #15A3AC; margin-right: 20px">
      Congratulations! 
    </p>
    <p class="lgP">You' ve reached a success</p>
  </div>
 
</div>
</div> -->

<!-- work upload congratulation toast -->
<!-- <div class="toastWrap">
  <button class="closeToast">
    <img src="../../../../../assets/close-toast.svg" height="" width="" alt="dp" /></button>
  <div>
    <div style="display: inline-flex; align-items:center; justify-content: flex-start;">
    <img src="../../../../../assets/congrats-toast.svg" height="" width="" alt="dp" />
    <p class="lgP toastHead" style="font-weight: 500; color: #15A3AC; margin-right: 20px">
      Congratulations! 
    </p>
    <p class="lgP">The work has been uploaded and we have frozen 40 coins from your account to pay your future advisor</p>
  </div>
  <div>
    <button class="button whiteBtn heightBtn">Undo</button>
    
  </div>
 
</div>
</div> -->

<!-- warning state toast -->
<!-- <div class="toastWrap">
  <button class="closeToast">
    <img src="../../../../../assets/close-toast.svg" height="" width="" alt="dp" /></button>
  <div>
    <div style="display: inline-flex; align-items:center; justify-content: flex-start;">
    <img src="../../../../../assets/warning-toast.svg" height="" width="" alt="dp" />
    <p class="lgP toastHead" style="font-weight: 500; color: #F39237; margin-right: 20px">
      Warning state! 
    </p>
    <p class="lgP">You better check the belt</p>
  </div>
 
</div>
</div> -->

<!-- warning toast -->
<!-- <div class="toastWrap">
  <button class="closeToast">
    <img src="../../../../../assets/close-toast.svg" height="" width="" alt="dp" /></button>
  <div>
    <div style="display: inline-flex; align-items:center; justify-content: flex-start;">
    <img src="../../../../../assets/warning-toast.svg" height="" width="" alt="dp" />
    <p class="lgP toastHead" style="font-weight: 500; color: #F39237; margin-right: 20px">
      Warning! 
    </p>
    <p class="lgP">No enough money</p>
  </div>
  <div>
    <button class="button whiteBtn heightBtn">Go to coin management</button>
    
  </div>
 
</div>
</div> -->

<!-- something wrong toast -->
<!-- <div class="toastWrap">
  <button class="closeToast">
    <img src="../../../../../assets/close-toast.svg" height="" width="" alt="dp" /></button>
  <div>
    <div style="display: inline-flex; align-items:center; justify-content: flex-start;">
    <img src="../../../../../assets/something-toast.svg" height="" width="" alt="dp" />
    <p class="lgP toastHead" style="font-weight: 500; color: #840032; margin-right: 20px">
     Something went wrong!
    </p>
    <p class="lgP">Please check and try again</p>
  </div>
 
</div>
</div> -->

<!-- pay attention toast -->
<!-- <div class="toastWrap">
  <button class="closeToast">
    <img src="../../../../../assets/close-toast.svg" height="" width="" alt="dp" /></button>
  <div>
    <div style="display: inline-flex; align-items:center; justify-content: flex-start;">
    <img src="../../../../../assets/warning-toast.svg" height="" width="" alt="dp" />
    <p class="lgP toastHead" style="font-weight: 500; color: #F39237; margin-right: 20px">
     Pay attention!
    </p>
    <p class="lgP">You can only give quick feedback while you are online. (Quick response)</p>
  </div>
 
</div>
</div> -->

<!-- about online toast -->
<!-- <div class="toastWrap">
  <button class="closeToast">
    <img src="../../../../../assets/close-toast.svg" height="" width="" alt="dp" /></button>
  <div>
    <div style="display: inline-flex; align-items:center; justify-content: flex-start;">
    <img src="../../../../../assets/about-online-toast.svg" height="" width="" alt="dp" />
    <p class="lgP toastHead" style="font-weight: 500; color: #15A3AC; margin-right: 20px">
      You are about to be online for 1hours
    </p>
    <p class="lgP">In this time frame you could have a quick requests (Due time of 1h - 3h)</p>
  </div>
 
</div>
</div> -->

<!-- print screen toast -->
<!-- <div class="toastWrap">
  <button class="closeToast">
    <img src="../../../../../assets/close-toast.svg" height="" width="" alt="dp" /></button>
  <div>
    <div style="display: inline-flex; align-items:center; justify-content: flex-start;">
    <img src="../../../../../assets/publish1-toast.svg" height="" width="" alt="dp" />
   
    <p class="lgP">Please do a print screen of your content and upload. Only format of an image is supported.</p>
  </div>
 
</div>
</div> -->

<!-- pay attention toast -->
<!-- <div class="toastWrap">
  <button class="closeToast">
    <img src="../../../../../assets/close-toast.svg" height="" width="" alt="dp" /></button>
  <div>
    <div style="display: inline-flex; align-items:center; justify-content: flex-start;">
    <img src="../../../../../assets/warning-toast.svg" height="" width="" alt="dp" />
    <p class="lgP toastHead" style="font-weight: 500; color: #F39237; margin-right: 20px">
     Pay attention!
    </p>
    <p class="lgP">All your content will be kept private and won't be published without your permission</p>
  </div>
 
</div>
</div> -->
