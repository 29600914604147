<div class="modalWrap termsModal">
    <div>
      <h2>Please Choose</h2>
    </div>
    <div class="content">
        <div class="inputWrap">
            <label class="checkboxWrap lgLabel" for="exampleCheck2"
              ><input
                type="checkbox"
                name="send_emails"
                [(ngModel)]="form.send_emails"
                class="form-check-input"
                id="exampleCheck2"
              />Yes, Send me Emails.
            </label>
          </div>
          <!------------- use this for advisor  -------------->
          <div class="friendReffer">
            <h6>Have you been referred through a friend?</h6>
            <div class="customRadio">
              <label class="form-check">
                <input
                  type="radio"
                  name="yes"
                  id="yes"
                  value=true
                  [(ngModel)]="form.referred_by_friend"
                />
                <span for="new">Yes</span>
              </label>
        
              <label class="form-check">
                <input
                  [(ngModel)]="form.referred_by_friend"
                  type="radio"
                  name="no"
                  id="no"
                  value=false
                />
                <span for="expire">No</span>
              </label>
            </div>
        </div>
    </div>
    <div class="btn-group">
      <button type="button" class="button heightBtn whiteBtn" (click)="activeModal.close('cancel')">Cancel</button>
      <button type="button" class="button heightBtn greenBtn" (click)="continue()">Submit</button>
    </div>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('cancel')">
    <img src="../../../../../assets/sign-exit-grey.svg" height="" width="" alt="sign-exit">
  </button>
  