import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-shared-example-list',
  templateUrl: './shared-example-list.component.html',
  styleUrls: ['./shared-example-list.component.css'],
})
export class SharedExampleListComponent implements OnInit {
  @Input() examples: any = [];
  searchText: any;
  filteredExamples: any = [];
  constructor(public sharedService: SharedService) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.filteredExamples = this.examples;
  }

  getFilteredExamples(examples: any) {
    this.filteredExamples = examples;
  }
}
