 
<div class="modalWrap termsModal guideLineModMain">
    <div>
        <h2>Guidelines for First-Time Advisors!</h2>
    </div>
    <div class="content">
        <ul>
            <li>Use the attached icons for left-to-right, right-to-left, and zoom functionalities.
                <div class="guidModLiImgsec">
                        <img src="../../../../../assets/arrow-expand-round.svg" height="" width="" alt="sign-exit">
                        <img src="../../../../../assets/left-to-right-block-quote.svg" height="" width="" alt="sign-exit">
                        <img src="../../../../../assets/right-to-left-block-quote.svg" height="" width="" alt="sign-exit">
                        
                </div>
            </li>
            <li>If you require additional information, feel free to ask users for more details in the messages.</li>
            <li>If a request is outside your area of expertise, kindly ask users to cancel the request through messages.
            </li>
            <li>Ensure that your package description includes all necessary information to save time in future requests.</li>
            <li>Please note that there may be multiple pages to review. Look out for the page icons below to navigate through the content:
                <div class="guidModLiImgsec">
                    <img src="../../../../../assets/pagination-icon.svg" height="" width="" alt="sign-exit">
                </div>
            </li>
        </ul>
    </div>
    <div class="btn-group text-center">
        <!-- <button type="button" class="button heightBtn whiteBtn" (click)="activeModal.close('cancel')">Cancel</button> -->
        <button type="button" class="button heightBtn greenBtn gotItBtn" (click)="activeModal.close('true')">Got It</button>
    </div>
</div>
<button type="button" class="close" aria-label="Close" (click)="activeModal.close('cancel')">
    <img src="../../../../../assets/sign-exit-grey.svg" height="" width="" alt="sign-exit">
</button>