import { URLConfig } from '../configs/url-config';

export class APIUrlUtils {
  constructor() {}

  public static fetchAdvisorDetailURL(advisorId: any) {
    return URLConfig.discoverAdvisors + '/' + advisorId + '/advisor_detail';
  }

  public static fetchWorkDetailURL(workId: any) {
    return URLConfig.uploadWork + workId + '/work_summary_detail/';
  }

  public static fetchWorkStatusUpdateURL(workId: any) {
    return URLConfig.uploadWork + workId + '/work_status_update/';
  }

  public static updateShareExample(workId: any) {
    return URLConfig.uploadWork + workId + '/example_share/';
  }

  public static fetchMarkAsViewedURL(workId: any) {
    return URLConfig.uploadWork + workId + '/update_work_summary_new_status/';
  }

  public static fetchTerminateWorkURL(workId: any) {
    return URLConfig.uploadWork + workId + '/delete_work_summary/';
  }

  public static fetchAnnotationsListURL(workId: any, imageId: any) {
    return URLConfig.annotationBase + workId + '/get_annotation/' + imageId;
  }

  public static fetchAnnotationsListReviewdURL(
    workId: any,
    imageId: any,
    advisorId: any
  ) {
    return (
      URLConfig.annotationBase +
      workId +
      '/get_work_annotation/' +
      imageId +
      '/' +
      advisorId
    );
  }

  public static fetchAdvisorRatingURL(workId: any, advisorId: any) {
    return URLConfig.advisorRating + workId + '/get_rating/' + advisorId + '/';
  }

  public static fetchAdvisorRequestURL(workId: any, advisorId: any) {
    return (
      URLConfig.advisorExampleRequest +
      workId +
      '/get_advisor_request/' +
      advisorId +
      '/'
    );
  }
}
