<div class="modalWrap termsModal">
  <div>
    <h2>Tips for content uploaders!</h2>
  </div>
  <div class="content">
    <ul>
      <li>
        <p>
          Provide as much context as possible for your content, including clear
          goals, constraints, focus, and background.
        </p>
      </li>
      <li>
        <p>Supported formats include URLs, PDFs, and print screens.</p>
      </li>
      <li>
        <p>
          For the best results, consider seeking advice from advisors in
          different disciplines.
        </p>
      </li>
    </ul>
    <!-- <p>How to do a print screen?</p> -->
    <img src="../../../../../assets/print-example.svg" alt="" srcset="" />
  </div>
  <div class="btn-group text-center">
    <!-- <button type="button" class="button heightBtn whiteBtn" (click)="activeModal.close('cancel')">Cancel</button> -->
    <button
      type="button"
      class="button heightBtn greenBtn"
      (click)="activeModal.close('true')"
    >
      Got It
    </button>
  </div>
</div>
<button
  type="button"
  class="close"
  aria-label="Close"
  (click)="activeModal.close('cancel')"
>
  <img
    src="../../../../../assets/sign-exit-grey.svg"
    height=""
    width=""
    alt="sign-exit"
  />
</button>
