import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { URLConfig } from 'src/app/configs/url-config';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { GetCategories } from 'src/app/stores/actions/category.actions';
import { CategoryState } from 'src/app/stores/states/category.state';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  authenticated: boolean = false;
  isAuthenticatedSubscription: any;

  @Select(CategoryState.isLoaded) categoryLoaded$!: Observable<boolean>;
  @Select(CategoryState.getCategory) categoryList$!: Observable<any[]>;

  constructor(
    public sharedService: SharedService,
    private route: Router,
    private apiService: ApiService,
    private _store: Store
  ) {
    this.isAuthenticatedSubscription =
      this.sharedService.authenticated.subscribe((value) => {
        this.authenticated = value;
      });
  }

  ngOnInit(): void {
    this.fetchFeedbackCategories();
  }

  feedBackCategories: any = [];
  subcategories: any[] = [];

  fetchFeedbackCategories() {
    this.categoryLoaded$.subscribe((res) => {
      if (!res) {
        this._store.dispatch(new GetCategories());
      } else {
        this.categoryList$.subscribe((data) => {
          this.feedBackCategories = data;
          data.forEach((res: any) => {
            if (res?.sub_category.length > 0) {
              this.subcategories = [
                ...this.subcategories,
                ...res?.sub_category,
              ];
            }
          });
          this.subcategories = this.getUniqueListBy(this.subcategories, 'name');
          this.sortSubCategories();
        });
      }
    });
  }

  getUniqueListBy(arr: any, key: any) {
    return [...new Map(arr.map((item: any) => [item[key], item])).values()];
  }

  sortSubCategories() {
    this.subcategories.sort(function (a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  }

  fatchExamples(subCat: any) {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    let title = this.sharedService.encodeURIComponentCustom(subCat.name);
    this.route.navigate([`/examples/category/${title}/` + subCat.id]);
  }

  ngOnDestory() {
    this.isAuthenticatedSubscription.unsubscribe();
  }
}
