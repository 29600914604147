import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { URLConfig } from 'src/app/configs/url-config';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-example-filters',
  templateUrl: './example-filters.component.html',
  styleUrls: ['./example-filters.component.css']
})
export class ExampleFiltersComponent implements OnInit {

  feedBackCategories: any = [];
  @Output() selectedCategory = new EventEmitter();
  constructor(private apiService: ApiService,) { }

  ngOnInit(): void {
    this.fetchFeedbackCategories();
  }

  selected_category: any;
  updateSelection(event: any, category: any) {
    this.selectedCategory.emit(category);
    this.selected_category = category;
  }

  fetchFeedbackCategories() {
    this.apiService
      .getRequest(URLConfig.allExperties)
      .subscribe((data: any) => {
        this.feedBackCategories = data;
      });
  }

}
