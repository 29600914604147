import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { URLConfig } from 'src/app/configs/url-config';
import { BalanceStateModal } from '../models/state.models';
import { Injectable } from '@angular/core';
import { GetCategories } from '../actions/category.actions';
import {
  GetBalances,
  ResetTransactionState,
} from '../actions/transaction.actions';

@State<BalanceStateModal>({
  name: 'balances',
  defaults: {
    balances: [],
    balancesLoaded: false,
  },
})
@Injectable()
export class BalanceState {
  constructor(private apiService: ApiService) {}

  @Selector()
  static getBalances(state: BalanceStateModal) {
    return state.balances;
  }

  @Selector()
  static isLoaded(state: BalanceStateModal) {
    return state.balancesLoaded;
  }

  @Action(GetBalances)
  async fetchUserPrograms({
    getState,
    setState,
  }: StateContext<BalanceStateModal>) {
    await this.apiService
      .getRequestPromise(URLConfig.advisorBalanceTransaction)
      .then((res) => {
        const state = getState();
        setState({
          ...state,
          balances: res,
          balancesLoaded: true,
        });
      });
  }

  @Action(ResetTransactionState)
  async resetState({ getState, setState }: StateContext<BalanceStateModal>) {
    const state = getState();
    setState({
      ...state,
      balances: [],
      balancesLoaded: false,
    });
  }
}
