import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-first-time-select-advisor-modal',
  templateUrl: './first-time-select-advisor-modal.component.html',
  styleUrls: ['./first-time-select-advisor-modal.component.css']
})
export class FirstTimeSelectAdvisorModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }


}
