import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { URLConfig } from 'src/app/configs/url-config';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-example-search-filter',
  templateUrl: './example-search-filter.component.html',
  styleUrls: ['./example-search-filter.component.css']
})
export class ExampleSearchFilterComponent implements OnInit {
  @Input() examples: any = [];
  @Output() filteredExamples = new EventEmitter();
  selectedCategory: any;
  searchText: any;
  feedBackCategories: any = [];


  constructor(private apiService: ApiService,) { }

  ngOnInit(): void {
    this.fetchFeedbackCategories();
  }


  fetchAdvisors() {
    this.filteredExamples.emit(this.filterByExampleTitle(this.examples, this.searchText.toLowerCase()));
  }

  filterByExampleTitle(examples: any, term: string) {
    var exm = examples.filter((v: any) => {
      if (v.work_detail.work_title.toLowerCase().indexOf(term) >= 0) {
        return true;
      } else
        return false;
    });
    return exm;
  }

  updateSelection(event: any, category: any) {
    this.selectedCategory = category == 'all' ? category == undefined : category;
    let filterEx = this.examples;
    if (this.selectedCategory)
      filterEx = this.examples.filter((ex: any) => ex.work_detail.category_detail.id == category.id);
    this.filteredExamples.emit(filterEx);

  }

  fetchFeedbackCategories() {
    this.apiService
      .getRequest(URLConfig.allExperties)
      .subscribe((data: any) => {
        this.feedBackCategories = data;
      });
  }



}
