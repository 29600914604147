import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-my-profile-picture',
  templateUrl: './my-profile-picture.component.html',
  styleUrls: ['./my-profile-picture.component.css'],
})
export class MyProfilePictureComponent implements OnInit, OnChanges {
  @Input() image: any;
  showImage: string = '';
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.showImage = this.image;
  }
}
