<div class="timeSlider customSlider">
  <div class="custom-slider">
    <ngx-slider
      [(value)]="value"
      [options]="options"
      (userChangeEnd)="sliderChanged($event)"
    ></ngx-slider>
    <!-- <ngx-slider [(value)]="value1" [options]="options1"></ngx-slider> -->
  </div>
</div>
