import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-no-examples-found',
  templateUrl: './no-examples-found.component.html',
  styleUrls: ['./no-examples-found.component.css'],
})
export class NoExamplesFoundComponent implements OnInit {
  @Input() category?: any = null;
  isHomePage: boolean = false;
  isExampleLoaded!: boolean;
  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService
  ) {
    const currentRoute = this.route.snapshot.routeConfig!.path;
    if (currentRoute?.includes('home') || currentRoute == '') {
      this.isHomePage = true;
    }
  }

  ngOnInit(): void {
    this.sharedService.isExampleLoaded.subscribe((res) => {
      this.isExampleLoaded = res!;
    });
  }
}
