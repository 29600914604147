import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-send-email-referred-by-modal',
  templateUrl: './send-email-referred-by-modal.component.html',
  styleUrls: ['./send-email-referred-by-modal.component.css']
})
export class SendEmailReferredByModalComponent implements OnInit {
  form: any = {referred_by_friend:false, send_emails:false};
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  continue(){
    this.activeModal.close(this.form);   
  }
}
