import { Injectable } from '@angular/core';

declare const Pusher: any;

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  pusher: any;

  constructor() { 
    this.pusher = new Pusher('68d1f6b8a68a1c63b49a', {cluster: 'us2'});
  }
}
