import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {
  @Input() heading: any = '';
  @Input() message: any = '';

  // @Output() confirmationEvent = new EventEmitter<any>();
  constructor(
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {}

  // confirmAction() {
  //   this.confirmationEvent.emit(true);
  // }
}
