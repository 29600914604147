import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-welcome-modal2',
  templateUrl: './welcome-modal2.component.html',
  styleUrls: ['./welcome-modal2.component.css'],
})
export class WelcomeModal2Component implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
