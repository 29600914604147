 
<div class="modalWrap termsModal">
    <div>
        <h2>Tips for valuable feedback!</h2>
    </div>
    <div class="content">
        <ul>
            <li>Begin with a nice comment to build a good relationship.
            </li>
            <li>Be helpful: Instead of just telling what is wrong, try to offer ideas to improve it.
            </li>
            <li>Teach new things: You can share your knowledge to help the person you're giving feedback to grow and get better.
            </li>
            <li>Keep the feedback focused on technique: Avoid subjective judgments about creative direction and focus on technical aspects.
            </li>
        </ul>
    </div>
    <div class="btn-group text-center">
        <!-- <button type="button" class="button heightBtn whiteBtn" (click)="activeModal.close('cancel')">Cancel</button> -->
        <button type="button" class="button heightBtn greenBtn" (click)="activeModal.close('true')">Got It</button>
    </div>
</div>
<button type="button" class="close" aria-label="Close" (click)="activeModal.close('cancel')">
    <img src="../../../../../assets/sign-exit-grey.svg" height="" width="" alt="sign-exit">
</button>