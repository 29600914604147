import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-category-modal',
  templateUrl: './add-category-modal.component.html',
  styleUrls: ['./add-category-modal.component.css'],
})
export class AddCategoryModalComponent implements OnInit {
  selectedSubcategories: any[] = [];
  @Input() filteredCategories: any;
  adding_new_category = true;
  finalCategory: any;
  formSubmitted: boolean = false;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  saveCategory() {
    this.formSubmitted = true;
    if (!this.finalCategory) {
      return;
    }
    this.activeModal.close(this.finalCategory);
  }

  categoryChanged(value: any) {
    this.formSubmitted = false;
    this.finalCategory = null;
    if (value) {
      this.selectedSubcategories = this.filteredCategories.find(
        (x: any) => x.id == value.id
      ).sub_category;
      if (this.selectedSubcategories.length == 0) {
        value['selected'] = true;
        this.finalCategory = value;
      }
    }
  }

  subcategoryChanged(value: any) {
    this.formSubmitted = false;
    this.finalCategory = {};
    value['selected'] = true;
    this.finalCategory = value;
  }
}
