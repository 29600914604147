import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { URLConfig } from 'src/app/configs/url-config';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-example-card',
  templateUrl: './example-card.component.html',
  styleUrls: ['./example-card.component.css'],
})
export class ExampleCardComponent implements OnInit, AfterViewInit {
  @Input() examples: any = [];
  @Input() pagination: boolean = true;
  page: any = 1;
  constructor(private _apiService: ApiService) {}

  encodeURIComponent(value: string): string {
    return encodeURIComponent(value.replace(/ /g, '-'));
  }

  ngOnInit(): void {}

  onPageChange(number: number) {
    this.page = number;
    // this.annotations = undefined;
    // if (this.workDetail.status !== '1') {
    //   this.getAnnotations();
    //   this.getImagesFromDom();
    // }
  }

  ngAfterViewInit(): void {}
}
