import { Component, OnInit } from '@angular/core';

import { PrimeNGConfig } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header-logged-out',
  templateUrl: './header-logged-out.component.html',
  styleUrls: ['./header-logged-out.component.css']
})
export class HeaderLoggedOutComponent implements OnInit {
  visibleSidebar1:any;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private primengConfig: PrimeNGConfig) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  navigateToLogin() {
    this.router.navigate(['auth/login'], {
      queryParams: { returnUrl: this.route.snapshot.routeConfig!.path }
    });
  }

}
