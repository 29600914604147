<div class="exampleFlex">
  <ng-container
    *ngFor="
      let example of examples
        | paginate : { itemsPerPage: 10, currentPage: page };
      let i = index
    "
  >
    <div class="exampleBox" *ngIf="pagination ? i >= 0 : i < 4">
      <div class="imgWrap">
        <img
          [src]="example?.work_detail?.files[0]?.url"
          height=""
          width=""
          alt="example1"
          style="cursor: pointer"
          routerLink="/examples/howto/{{
            example.work_detail.category_detail.name
          }}/{{ example.work_detail.work_title }}/{{ example.id }}"
        />
        <label>{{ example.work_detail.category_detail.name }}</label>
        <div class="dropdown exampleShareDrop">
          <button
            class="btn"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              src="../../../../../assets/ev-share-icon.svg"
              height=""
              width=""
              alt="name"
            />
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <div>
              <p>Share:</p>
              <ul>
                <li>
                  <a href="#"
                    ><img
                      src="../../../../../assets/share-icon1.svg"
                      height=""
                      width=""
                      alt="name"
                  /></a>
                </li>
                <li>
                  <a href="#"
                    ><img
                      src="../../../../../assets/share-icon2.svg"
                      height=""
                      width=""
                      alt="name"
                  /></a>
                </li>
                <li>
                  <a href="#"
                    ><img
                      src="../../../../../assets/share-icon3.svg"
                      height=""
                      width=""
                      alt="name"
                  /></a>
                </li>
                <li>
                  <a href="#"
                    ><img
                      src="../../../../../assets/share-icon4.svg"
                      height=""
                      width=""
                      alt="name"
                  /></a>
                </li>
                <li>
                  <a href="#"
                    ><img
                      src="../../../../../assets/share-icon5.svg"
                      height=""
                      width=""
                      alt="name"
                  /></a>
                </li>
                <li>
                  <a href="#"
                    ><img
                      src="../../../../../assets/share-icon6.svg"
                      height=""
                      width=""
                      alt="name"
                  /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="flex justify-between items-start">
          <div class="title-desc">
            <h6>{{ example.work_detail.work_title }}</h6>
            <p style="color: black; text-decoration: none" [innerHtml]="
              example.work_detail.work_description?.length > 20 ? example.work_detail.work_description.substr(0, 20) + '...': example.work_detail.work_description"></p>
            <p>
              
            </p>
          </div>
        </div>
        <div class="exampleBoxTotal">
          <h3>Total number of advisors</h3>
          <p>
            ({{ example?.work_detail?.selected_advisors.length }}
            {{
              example?.work_detail?.selected_advisors.length == 1 ||
              example?.work_detail?.selected_advisors.length == 0
                ? "Advisor"
                : "Advisors"
            }})
          </p>
        </div>

        <div class="exampleBoxFlex">
          <div class="dpWrap">
            <app-profile-picture
              [userDetails]="example?.work_detail?.best_advisor"
            ></app-profile-picture>
            <!-- <img [src]="example?.work_detail?.best_advisor?.image" height="" width="" alt="dp" /> -->
          </div>
          <div>
            <h5>Best Advice</h5>
            <div class="exampleBoxFlex">
              <h4>{{ example?.work_detail?.best_advisor?.full_name }}</h4>
              <p>
                {{ example?.work_detail?.best_advisor?.likes }}
                {{
                  example?.work_detail?.best_advisor?.likes == 1 ||
                  example?.work_detail?.best_advisor?.likes == 0
                    ? "Like"
                    : "Likes"
                }}
              </p>
            </div>
          </div>
        </div>
        <button
          class="button shortBtn whiteBtn"
          [routerLink]="[
            '/examples/',
            encodeURIComponent(example.work_detail.category_detail.name),
            encodeURIComponent(example.work_detail.work_title),
            example.id
          ]"
        >
          View
        </button>
      </div>
    </div>
  </ng-container>
</div>
<!-- <div class="exampleFlex">
  <div class="exampleBox">
    <div class="imgWrap">
      <img
        src="../../../../../assets/example1.png"
        height=""
        width=""
        alt="example1"
      />
      <label>Social Media</label>
      <div class="dropdown exampleShareDrop">
        <button
          class="btn"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            src="../../../../../assets/ev-share-icon.svg"
            height=""
            width=""
            alt="name"
          />
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div>
            <p>Share:</p>
            <ul>
              <li>
                <a href="#"
                  ><img
                    src="../../../../../assets/share-icon1.svg"
                    height=""
                    width=""
                    alt="name"
                /></a>
              </li>
              <li>
                <a href="#"
                  ><img
                    src="../../../../../assets/share-icon2.svg"
                    height=""
                    width=""
                    alt="name"
                /></a>
              </li>
              <li>
                <a href="#"
                  ><img
                    src="../../../../../assets/share-icon3.svg"
                    height=""
                    width=""
                    alt="name"
                /></a>
              </li>
              <li>
                <a href="#"
                  ><img
                    src="../../../../../assets/share-icon4.svg"
                    height=""
                    width=""
                    alt="name"
                /></a>
              </li>
              <li>
                <a href="#"
                  ><img
                    src="../../../../../assets/share-icon5.svg"
                    height=""
                    width=""
                    alt="name"
                /></a>
              </li>
              <li>
                <a href="#"
                  ><img
                    src="../../../../../assets/share-icon6.svg"
                    height=""
                    width=""
                    alt="name"
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <h6>Diam nibh facilisi nisl lectus</h6>
      <p>
        Enim pretium senectus vitae, porttitor commodo. Pharetra molestie
        porttitor euismod semper quam elit. At amet, non amet lectus
        elementum dictum. Id lacus placerat purus amet ac convallis
        duis...
      </p>
      <div class="exampleBoxTotal">
        <h3>Total number of advisors</h3>
        <p>(34 Advisors)</p>
      </div>
      <div class="exampleBoxFlex">
        <div class="dpWrap">
          <img
            src="../../../../assets/advisor-dp.svg"
            height=""
            width=""
            alt="dp"
          />
        </div>
        <div>
          <h5>Best Advice</h5>
          <div class="exampleBoxFlex">
            <h4>Allison Lipshutz</h4>
            <p>235 Likes</p>
          </div>
        </div>
      </div>

      <button class="button shortBtn whiteBtn">View</button>
    </div>
  </div>
 
</div> -->
<div class="list paginationWrap" *ngIf="pagination && examples.length > 0">
  <pagination-controls
    previousLabel=""
    nextLabel=""
    (pageChange)="onPageChange($event)"
  ></pagination-controls>
</div>
