<div class="modalWrap termsModal">
    <div>
        <!-- <h2>Tips for content uploaders!</h2> -->
    </div>
    <div class="content">
        <p>
            All your information will be kept private and won't be published without your permission.        
        </p>
    </div>
    <div class="btn-group text-center">
        <!-- <button type="button" class="button heightBtn whiteBtn" (click)="activeModal.close('cancel')">Cancel</button> -->
        <button type="button" class="button heightBtn greenBtn" (click)="activeModal.close('true')">Got It</button>
    </div>
</div>
<button type="button" class="close" aria-label="Close" (click)="activeModal.close('cancel')">
    <img src="../../../../../assets/sign-exit-grey.svg" height="" width="" alt="sign-exit">
</button>